import { useQuery } from "@apollo/client";
import {
  GET_CURRENT_USER_BOX_ACCESS_TOKEN_QUERY,
  GET_CURRENT_USER_CHAT_ACCESS_TOKEN_QUERY,
} from "~/api";

export const getBoxAccessToken = ({ skip }) => {
  const { data: { currentUser: user = {} } = {}, loading } = useQuery(
    GET_CURRENT_USER_BOX_ACCESS_TOKEN_QUERY,
    {
      skip: skip,
      fetchPolicy: "network-only",
      pollInterval: 1000 * 60 * 30, // poll every half an hour
    },
  );
  return { user, loading };
};

export const getChatAccessToken = ({ skip }) => {
  const { data: { currentUser: user = {} } = {}, loading } = useQuery(
    GET_CURRENT_USER_CHAT_ACCESS_TOKEN_QUERY,
    {
      skip: skip,
      fetchPolicy: "network-only",
      pollInterval: 1000 * 60 * 15, // poll every 15 minutes
    },
  );
  return { user, loading };
};
