import React from "react";
import ProductionLineListItem from "../components/productionLine/ProductionLineListItem";
import { NotFoundComponent } from "../components/_notFoundMessage";
import { NoUserAssignedIcon } from "../icons";
import Spinner from "../components/_spinner";
import { Waypoint } from "react-waypoint";
import { ITEMS_BEFORE_PAGE, ITEMS_PER_PAGE } from "../constants/global";
import { ProductionLineLoaderComponent } from "../components/skeletonLoaders/_productionLindLoaders";

const ProductionLinesListing = ({
  intl,
  productionLines = [],
  loading,
  hasProductionLines,
  handleFetchMore,
  totalCount,
}) => {
  const handleScrollToBottom = (event) => {
    if (
      productionLines.length >= ITEMS_PER_PAGE &&
      !loading &&
      event?.previousPosition !== "above" &&
      productionLines.length < totalCount
    ) {
      handleFetchMore({
        limit: ITEMS_PER_PAGE,
        skip: productionLines?.length,
      });
    }
  };

  if (loading && !productionLines.length) {
    return <Spinner />;
  }

  return (
    <>
      {!hasProductionLines && (
        <NotFoundComponent
          icon={<NoUserAssignedIcon />}
          title={intl.messages?.productionLines?.noProductionLines}
        />
      )}
      {hasProductionLines && !productionLines?.length && (
        <NotFoundComponent
          icon={<NoUserAssignedIcon />}
          title={intl.messages?.common?.noResultFound}
        />
      )}
      {productionLines.map((productionLine, index) => (
        <React.Fragment key={productionLine._id}>
          <ProductionLineListItem intl={intl} productionLine={productionLine} />
          {index === productionLines.length - ITEMS_BEFORE_PAGE && (
            <Waypoint onEnter={handleScrollToBottom} />
          )}
        </React.Fragment>
      ))}
      {loading && <ProductionLineLoaderComponent total={1} />}
    </>
  );
};

export default ProductionLinesListing;
