import React from "react";
import { Minus, Plus } from "phosphor-react";

export const Counter = ({
  countValue,
  setCountValue,
  incrementCount,
  decrementCount,
  readOnlyInput = true,
  wrapperClassname = "",
  decrementButtonClassname = "",
  incrementButtonClassname = "",
}) => {
  return (
    <div className={`part-counter new ${wrapperClassname}`}>
      <button
        className={`count-button ${decrementButtonClassname}`}
        onClick={decrementCount}
        disabled={countValue === 0}
      >
        <Minus size={12} weight="bold" />
      </button>
      <input
        readOnly={readOnlyInput}
        type="number"
        className={`count-value ${
          countValue > 0 ? "u-text-color-blue-v2-100" : ""
        }`}
        value={
          countValue.toString().length > 1
            ? countValue.toString().replace(/^0+/, "")
            : countValue.toString()
        }
        min="0"
        onBlur={() => {
          if (!countValue) {
            setCountValue(0);
          }
        }}
        onChange={(e) => {
          if (e.target.value === "") {
            setCountValue(0);
            return;
          }
          setCountValue(parseInt(e.target.value));
        }}
      />
      <button
        className={`count-button ${incrementButtonClassname}`}
        onClick={incrementCount}
      >
        <Plus size={12} weight="bold" />
      </button>
    </div>
  );
};
