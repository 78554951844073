import { UPDATE_PERSONAL_SETTINGS } from "#/src/api";
import { useMutation } from "@apollo/client";

export const useUpdatePersonalGeneralSettings = () => {
  const [update, { loading, error }] = useMutation(UPDATE_PERSONAL_SETTINGS);

  return {
    loading,
    error,
    updatePersonalGeneralSettings: async (personalSettings) =>
      update({
        variables: {
          personalSettings,
        },
      }),
  };
};
