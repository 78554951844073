import { ITEMS_PER_PAGE } from "#/src/constants/global";
import { useAuth } from "#/src/components/general";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_OWN_FACILITY_TICKET_BY_ID,
  LIST_OWN_CUSTOMER_TICKETS,
  GET_SINGLE_LIST_TICKET_BY_ID_QUERY,
} from "~/api";

import { ticketTransformer } from "~/transformers";

export const getTicketById = (id) => {
  const { data: { getOwnFacilityTicketById } = {}, loading } = useQuery(
    GET_OWN_FACILITY_TICKET_BY_ID,
    {
      variables: {
        id,
      },
      skip: !id,
    },
  );

  return {
    loading,
    groupedByDate: ticketTransformer.groupedByDate(getOwnFacilityTicketById),
    ticket: getOwnFacilityTicketById,
  };
};

export const useListOwnCustomerTickets = ({
  where,
  limit = ITEMS_PER_PAGE,
  skip = 0,
}) => {
  const variables = {
    params: {
      ...(where ? { where } : {}),
      limit,
      skip,
    },
  };

  const {
    data: { listOwnCustomerTickets = {} } = {},
    loading,
    fetchMore,
    error,
  } = useQuery(LIST_OWN_CUSTOMER_TICKETS, {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const handleFetchMore = ({ limit, skip }) => {
    fetchMore({
      variables: {
        ...variables,
        params: {
          ...variables.params,
          limit,
          skip,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedTickets = [
          ...previousResult.listOwnCustomerTickets.tickets,
          ...fetchMoreResult.listOwnCustomerTickets.tickets,
        ];

        const updates = {
          ...previousResult,
          listOwnCustomerTickets: {
            ...previousResult.listOwnCustomerTickets,
            ...fetchMoreResult.listOwnCustomerTickets,
            tickets: mergedTickets,
          },
        };

        return updates;
      },
    });
  };

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    tickets = [],
  } = listOwnCustomerTickets;

  return {
    tickets,
    loading,
    error,
    handleFetchMore,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
  };
};

export const useGetWorkOrderTypes = () => {
  const { user } = useAuth();

  return user?.oem?.ticketTypes?.filter(
    (type) => !type?.isSystem && !type?.isInternal,
  );
};

export const useGetTicketByIdForList = () => {
  const [getTicket] = useLazyQuery(GET_SINGLE_LIST_TICKET_BY_ID_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  return { getTicketById: async (id) => getTicket({ variables: { id } }) };
};
