import React from "react";
import ContentLoader from "react-content-loader";

export const MachineLoaderComponent = ({
  total = 2,
  width = 1154,
  height = 96,
}) => {
  const loaders = [];
  for (let i = 0; i < total; i++) {
    loaders.push(
      <ContentLoader
        className="machine-content-loader"
        key={i}
        width={width}
        height={height}
      >
        <rect x="13" y="12" rx="8" ry="8" width="93" height="70" />
        <rect x="125" y="27" rx="0" ry="0" width="200" height="16" />
        <rect x="125" y="54" rx="0" ry="0" width="150" height="13" />
        <rect x="970" y="32" rx="0" ry="0" width="120" height="8" />
        <rect x="970" y="49" rx="0" ry="0" width="120" height="13" />
        <rect x="48" y="113" rx="0" ry="0" width="1102" height="1" />
      </ContentLoader>,
    );
  }

  return loaders;
};
