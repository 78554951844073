import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ROUTES_SETTINGS from "$/settings/routes-resolvers.json";
import { COLOR } from "#/src/constants/colors";

const { CLIENT: { ROUTES: { FACILITY_LOGIN: LOGIN } = {} } = {} } =
  ROUTES_SETTINGS;

function useQuery() {
  return new URLSearchParams(useLocation().search.toString());
}

const ForgotPasswordSuccessForm = ({
  callback = () => {},
  intl,
  color,
  isLighterBackground,
  buttonStyle,
}) => {
  const history = useHistory();
  const { lang, modifier } = useParams();
  const [loading, setLoading] = useState(false);

  let query = useQuery();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: query.get("email"),
    },
  });

  const onSubmit = (data) => {
    setLoading(false);
    callback(data);
  };

  const onError = () => {
    setLoading(false);
  };

  const handleThankyouClick = () => {
    const url = `/${lang}${LOGIN}${modifier ? "/" + modifier : ""}`;
    history.replace(url);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
      <div className="form-group auth-submit">
        <input hidden name="email" {...register("email", { required: true })} />
        <span
          style={{ background: color, ...buttonStyle }}
          className="c-button c-button--noHover c-button--primary u-cursor-pointer"
          onClick={handleThankyouClick}
        >
          {intl?.messages?.forgotPasswordSuccess?.thanks}
        </span>
        <p className="auth-submitResend">
          {intl?.messages?.forgotPasswordSuccess?.noEmail}{" "}
          <button
            style={{ color: isLighterBackground ? COLOR.$gray_v2_100 : color }}
            type="submit"
            onClick={() => {
              setLoading(true);
              handleSubmit(onSubmit, onError);
            }}
          >
            {!loading
              ? intl?.messages?.forgotPasswordSuccess?.form?.submit?.default
              : intl?.messages?.forgotPasswordSuccess?.form?.submit?.loading}
          </button>
        </p>
      </div>
    </form>
  );
};

export default ForgotPasswordSuccessForm;
