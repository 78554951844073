import { MagnifyingGlass } from "phosphor-react";
import { func, oneOf, string } from "prop-types";
import React from "react";

const Search = ({
  content,
  size,
  onChange,
  placeholder = "",
  onBlur = () => {},
  className,
}) => {
  const timer = React.useRef();
  const inputRef = React.useRef();
  const inputValueRef = React.useRef("");

  const [active, setActive] = React.useState(!!content);

  const handleChange = (e) => {
    inputValueRef.current = e.target.value;
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      onChange(inputValueRef.current);
    }, 100);
  };

  const handleBlur = (e) => {
    onBlur(e.target.value);
    if (inputRef.current.value) return;

    inputRef.current.value = "";
    inputValueRef.current = "";
  };

  React.useEffect(() => {
    let mount = true;
    if (active && mount) {
      inputRef.current?.focus();
    }

    return () => {
      mount = false;
    };
  }, [active]);

  React.useEffect(() => {
    let mount = true;
    if (mount && inputRef.current !== document.activeElement) {
      setActive((p) => !!content);
    }

    return () => {
      mount = false;
    };
  }, [content]);

  return (
    <div
      className={`search ${size} ${active ? "active" : ""} ${className || ""}`}
      onClick={() => {
        setActive(true);
      }}
      onBlur={() => {
        if (content || inputValueRef.current) return;

        setActive(false);
      }}
    >
      <MagnifyingGlass size={16} className="search-icon" />
      <input
        ref={inputRef}
        className="search-input"
        disabled={!active}
        placeholder={active || size === "full" ? placeholder : ""}
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={content}
      />
    </div>
  );
};

Search.propTypes = {
  content: string,
  size: oneOf(["full", "large", "medium", "small"]),
  onChange: func,
  onBlur: func,
};

Search.defaultProps = {
  content: "",
  size: "large",
  onChange: () => {},
  onBlur: () => {},
};

export default Search;
