import { useQuery } from "@apollo/client";
import {
  GET_OWN_CUSTOMER_MACHINE,
  GET_OWN_CUSTOMER_MACHINE_BY_UUID_QUERY,
  GET_OWN_CUSTOMER_MACHINE_PARTS,
  GET_OWN_CUSTOMER_MACHINE_PARTS_BY_UUID_QUERY,
  LIST_OWN_CUSTOMER_MACHINES,
} from "~/api";
import {
  GET_MACHINE_3D_AUTH_TOKEN_QUERY,
  GET_MACHINE_FOLDERS_BY_ID,
  GET_MACHINE_FOLDER_ID_QUERY,
  GET_OWN_OEM_TEMPLATE_FOLDERS_BY_ID,
  LIST_OWN_OEM_MACHINE_HISTORY,
  LIST_OWN_OEM_MACHINE_TICKET_HISTORY_BY_ID_QUERY,
} from "../../api/_queries";
import { ITEMS_PER_PAGE } from "#/src/constants/global";

export const getMachineById = (id) => {
  const { data: { getOwnCustomerMachineById: machine = {} } = {}, loading } =
    useQuery(GET_OWN_CUSTOMER_MACHINE, {
      variables: {
        id,
      },
      skip: !id,
    });

  return [machine, loading];
};

export const getMachinePartsById = (id) => {
  const { data: { getOwnCustomerMachineById: machine = {} } = {}, loading } =
    useQuery(GET_OWN_CUSTOMER_MACHINE_PARTS, {
      variables: {
        id,
      },
      skip: !id,
    });

  return [machine?.inventoryParts, loading];
};

export const getMachineByUuid = (uuid) => {
  const { data: { getOwnCustomerMachineByUuid: machine = {} } = {} } = useQuery(
    GET_OWN_CUSTOMER_MACHINE_BY_UUID_QUERY,
    {
      variables: {
        uuid,
      },
      skip: !uuid,
    },
  );

  return [machine];
};

export const getMachinePartsByUuid = (uuid) => {
  const { data: { getOwnCustomerMachineByUuid: machine = {} } = {} } = useQuery(
    GET_OWN_CUSTOMER_MACHINE_PARTS_BY_UUID_QUERY,
    {
      variables: {
        uuid,
      },
      skip: !uuid,
    },
  );

  return [machine?.inventoryParts];
};

export const getMachinesByParams = ({
  where,
  limit = ITEMS_PER_PAGE,
  skip = 0,
}) => {
  const variables = {
    params: {
      ...(where ? { where } : {}),
      limit,
      skip,
    },
  };

  const {
    data: { listOwnCustomerMachines = {} } = {},
    loading,
    fetchMore,
    error,
  } = useQuery(LIST_OWN_CUSTOMER_MACHINES, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const handleFetchMore = ({ limit, skip }) => {
    fetchMore({
      variables: {
        ...variables,
        params: {
          ...variables.params,
          limit,
          skip,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedMachines = [
          ...previousResult.listOwnCustomerMachines.machines,
          ...fetchMoreResult.listOwnCustomerMachines.machines,
        ];

        const updates = {
          ...previousResult,
          listOwnCustomerMachines: {
            ...previousResult.listOwnCustomerMachines,
            ...fetchMoreResult.listOwnCustomerMachines,
            machines: mergedMachines,
          },
        };

        return updates;
      },
    });
  };

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    machines = [],
  } = listOwnCustomerMachines;

  return {
    machines,
    loading,
    error,
    handleFetchMore,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
  };
};

export const listMachineTicketHistoryById = (id) => {
  const { data: { listOwnOemMachineTicketHistoryById } = {}, loading } =
    useQuery(LIST_OWN_OEM_MACHINE_TICKET_HISTORY_BY_ID_QUERY, {
      variables: {
        id,
      },
      skip: !id,
    });

  return {
    loading,
    machineHistory: listOwnOemMachineTicketHistoryById,
  };
};

export const getMachineFolderId = (machineId) => {
  const { data: { getMachineFolderId: machineFolderId = 0 } = {}, loading } =
    useQuery(GET_MACHINE_FOLDER_ID_QUERY, {
      variables: {
        machineId,
      },
      skip: !machineId,
    });

  return {
    loading,
    machineFolderId,
  };
};

export const getMachineTemplateFolderId = (templateId) => {
  const {
    data: { getOwnOemTemplateFolderStructureById: boxFolders = {} } = {},
    loading,
  } = useQuery(GET_OWN_OEM_TEMPLATE_FOLDERS_BY_ID, {
    variables: {
      templateId,
    },
    skip: !templateId,
  });

  return {
    templateBoxFolders: boxFolders || {},
    loading,
  };
};

export const getMachineFolderIds = (machineId) => {
  const {
    data: { getMachineFoldersIds: machineBoxFolders = {} } = {},
    loading,
  } = useQuery(GET_MACHINE_FOLDERS_BY_ID, {
    variables: {
      machineId,
    },
    skip: !machineId,
  });

  return {
    machineBoxFolders: machineBoxFolders || {},
    loading,
  };
};

export const useListOwnOemMachineHistory = ({
  limit = ITEMS_PER_PAGE,
  skip = 0,
  searchQuery,
  where,
} = {}) => {
  const variables = {
    params: {
      limit,
      skip,
      where: {
        searchQuery,
        ...(where || {}),
      },
    },
  };

  const {
    data: { listOwnOemMachineHistory = {} } = {},
    loading,
    refetch,
    fetchMore,
  } = useQuery(LIST_OWN_OEM_MACHINE_HISTORY, {
    variables,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    history = [],
    ticketCount,
  } = listOwnOemMachineHistory ?? {
    currentPage: 0,
    limit: 0,
    skip: 0,
    totalCount: 0,
    history: [],
    ticketCount: 0,
  };

  const handleFetchMore = ({ limit, skip }) => {
    fetchMore({
      variables: {
        params: {
          limit,
          skip,
          where: {
            searchQuery,
            ...(where || {}),
          },
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedHistory = [
          ...previousResult.listOwnOemMachineHistory.history,
          ...fetchMoreResult.listOwnOemMachineHistory.history,
        ];

        const updates = {
          ...previousResult,
          listOwnOemMachineHistory: {
            ...previousResult.listOwnOemMachineHistory,
            ...fetchMoreResult.listOwnOemMachineHistory,
            history: mergedHistory,
          },
        };

        return updates;
      },
    });
  };

  return {
    history,
    handleFetchMore,
    refetch,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    loading,
    ticketCount,
  };
};

export const useGetMachine3DAuthToken = (id) => {
  const { data: { getMachine3DAuthToken } = {}, loading } = useQuery(
    GET_MACHINE_3D_AUTH_TOKEN_QUERY,
    {
      variables: {
        id,
      },
      skip: !id,
      fetchPolicy: "network-only",
    },
  );

  return {
    loading,
    token: getMachine3DAuthToken,
  };
};
