import { flatten } from "flat";

import locales from "../config/locales";
import langFiles from "../config/lang-files";

const data = {};

locales.forEach((locale) => {
  let resources = {};

  langFiles.forEach(async (langFile) => {
    let file = null;
    // eslint-disable-next-line import/no-dynamic-require, global-require
    file = await import(`./${locale.isoLocale}/${langFile}.json`);
    resources[langFile] = file.default;
  });

  resources = flatten(resources);
  data[locale.language] = resources;
});

export default data;
