import React from "react";
import { useHistory } from "react-router-dom";
import Loading from "~/components/general/_loading";
import useAuth from "~/components/general/_use-auth";

const AuthPageGuard = ({ children }) => {
  const { isLoggedIn, loading } = useAuth();
  const history = useHistory();

  React.useEffect(() => {
    if (isLoggedIn) {
      history.replace("/");
    }
  }, [isLoggedIn]);

  if (loading === undefined || loading) return <Loading />;
  if (isLoggedIn !== undefined && !isLoggedIn) return <>{children}</>;
  return null;
};

export default AuthPageGuard;
