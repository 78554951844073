import React from "react";
import Label, { LABEL_SIZES } from "@shared/ui/Label";

const IconInfo = ({ icon, info }) => {
  return (
    <div className="iconinfo-wrapper">
      <div className="iconinfo-content">
        <div className="flex items-center justify-center w-2xl h-2xl mr-sm bg-brand-lightest text-brand rounded-full">
          {icon}
        </div>
        <Label size={LABEL_SIZES.MEDIUM} className="flex-1">
          {info}
        </Label>
      </div>
    </div>
  );
};

export default IconInfo;
