import React from "react";
import { NoTicketsLargeIcon, NoTicketsSmallIcon } from "../../icons";

export const NoTicketsSmall = ({ icon, title }) => {
  return (
    <div className="no-tickets-wrapper">
      <span className="no-tickets-icon">{icon || <NoTicketsSmallIcon />}</span>

      <p className="no-tickets-title">{title || "There is no tickets found"}</p>
    </div>
  );
};

export const NoTicketsLarge = ({ icon, title }) => {
  return (
    <div className="no-tickets-wrapper full-page">
      <span className="no-tickets-icon">{icon || <NoTicketsLargeIcon />}</span>

      <p className="no-tickets-title">{title || "There is no tickets found"}</p>
    </div>
  );
};
