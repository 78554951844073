import React from "react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { getComponentThumbnail } from "#/src/utils";

const ComponentListItem = ({ intl, component, machineId }) => {
  const { messages } = useIntl();
  const thumbnail = React.useMemo(
    () => getComponentThumbnail(component),
    [component],
  );
  return (
    <Link
      to={`/${intl?.locale}/app/assets/machines/${machineId}/component/${component?._id}`}
    >
      <div className="component-list-item-wrapper">
        <figure
          className={
            "machine-thumbnail machine-thumbnail--small component-image"
          }
        >
          <img
            src={thumbnail}
            alt={component?.name}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/images/default/thumbnail.svg";
            }}
          />
        </figure>
        <div className="component-detail">
          <div className="component-name u-truncate">
            <Headline
              size={HEADLINE_SIZES.X_SMALL}
              className="overflow-hidden text-ellipsis whitespace-nowrap capitalize"
              title={component?.name}
            >
              {component?.name}
            </Headline>
          </div>
          <div className="component-id u-truncate">
            <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
              {messages?.components.labels.componentId}
              <>: &nbsp; &nbsp;</>
            </BodyText>
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              className="truncate"
              title={component?.componentId}
            >
              {component?.componentId}
            </BodyText>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ComponentListItem;
