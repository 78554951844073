import React from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { getMachineThumbnail } from "~/utils";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Label, { LABEL_SIZES } from "@shared/ui/Label";

const MachineListItem = ({ intl, machine, variant = "small", inline }) => {
  const { messages } = useIntl();
  const thumbnail = React.useMemo(() => {
    return getMachineThumbnail(machine);
  }, [machine]);
  return (
    <Link to={`/${intl?.locale}/app/assets/machines/${machine?._id}`}>
      <div className="list-item--boxed u-flex u-items-center machine-list-item">
        <div className="u-flex u-items-center u-flex-grow-1">
          <figure className={`machine-thumbnail machine-thumbnail--${variant}`}>
            <img
              src={thumbnail}
              alt={machine?.name}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/images/default/thumbnail.svg";
              }}
            />
          </figure>
          <div className="u-flex u-flex-column machine-list-item-title--container">
            <div className="u-inline-flex u-items-center">
              <Headline
                size={HEADLINE_SIZES.X_SMALL}
                className="overflow-hidden text-ellipsis whitespace-nowrap capitalize hidden md:flex"
                title={machine?.name}
              >
                {machine?.name}
              </Headline>
              <Label
                size={LABEL_SIZES.SMALL}
                className="w-full line-clamp-2 text-ellipsis whitespace-normal capitalize mb-xs md:hidden"
                title={machine?.name}
              >
                {machine?.name}
              </Label>
              <div
                className={`machine-serial-number u-flex u-flex-column sm-mobile-element ${
                  inline && "inline"
                }`}
              >
                <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
                  {messages?.machines?.machineDetails.detailsTab.serialNum}
                  {inline && <>: &nbsp; &nbsp;</>}
                </BodyText>
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  className="truncate max-w-20"
                  title={machine?.serialNumber}
                >
                  {machine?.serialNumber}
                </BodyText>
              </div>
            </div>
          </div>
        </div>

        <div className="u-flex u-items-center u-padding-r-5 sm-desktop-element u-content-box w200">
          <div className="u-margin-r-0">
            <div
              className={`machine-serial-number u-flex u-flex-column ${
                inline && "inline"
              }`}
            >
              <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
                {messages?.machines?.machineDetails.detailsTab.serialNum}
                {inline && <>: &nbsp; &nbsp;</>}
              </BodyText>
              <BodyText
                size={BODY_TEXT_SIZES.X_SMALL}
                className="truncate max-w-20"
                title={machine?.serialNumber}
              >
                {machine?.serialNumber}
              </BodyText>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default MachineListItem;
