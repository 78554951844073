import React, { useMemo } from "react";
import PAID_FEATURES from "$/settings/paid-features.json";
import { MakulaLogoWhiteSVG } from "~/icons";
import { getEnums, hexToHSL, hexToHsb } from "~/utils";
import { MAKULA_DEFAULT_COLOR } from "~/constants/global";
import { COLOR } from "../constants/colors";
const PaidFeatureEnums = getEnums(PAID_FEATURES, "reference");

const useBrandingProperties = (oem, intl) => {
  const isBrandingEnable = useMemo(
    () => oem?.paidFeatures?.includes(PaidFeatureEnums?.branding),
    [oem],
  );

  const brandingProperties = useMemo(() => {
    if (isBrandingEnable) {
      return {
        backgroundColor: oem?.backgroundColor || MAKULA_DEFAULT_COLOR,
        heading: oem?.heading || intl.messages?.login?.heroTitle,
        subHeading: oem?.subHeading || intl.messages?.login?.heroSubtitle,
        paragraph: oem?.paragraph ?? "",
        brandLogo: oem?.brandLogo ? (
          <div className="brand-logo">
            <img src={oem?.brandLogo} />
          </div>
        ) : (
          ""
        ),
      };
    } else {
      return {
        backgroundColor: MAKULA_DEFAULT_COLOR,
        heading: intl.messages?.login?.heroTitle,
        subHeading: intl.messages?.login?.heroSubtitle,
        brandLogo: (
          <>
            <span className="auth-footerCopy" style={{ color: oem?.textColor }}>
              {intl.messages?.common?.poweredBy}
            </span>
            <MakulaLogoWhiteSVG />
          </>
        ),
      };
    }
  }, [isBrandingEnable]);

  const isLighterBackground = useMemo(() => {
    if (!oem?.backgroundColor || !isBrandingEnable) return false;

    const { l } = hexToHSL(oem.backgroundColor);
    const { s } = hexToHsb(oem.backgroundColor);

    if (l > 85 || s < 30) return true;

    return false;
  }, [isBrandingEnable]);

  const lightButtonStyles = useMemo(() => {
    return isLighterBackground
      ? {
          color: COLOR.$gray_v2_100,
          background: oem?.backgroundColor || MAKULA_DEFAULT_COLOR,
          border: `1px solid ${COLOR.$gray_v2_100}`,
        }
      : {};
  }, [isLighterBackground, oem?.backgroundColor]);

  return {
    isBrandingEnable,
    isLighterBackground,
    branding: brandingProperties,
    buttonStyle: lightButtonStyles,
  };
};

export default useBrandingProperties;
