import { WarningCircle } from "phosphor-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

const ForgotPasswordForm = ({
  callback = () => {},
  intl,
  color,
  isLighterBackground,
  buttonStyle,
}) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { email } = watch();

  const onSubmit = (data) => {
    callback(data, setLoading);
  };

  const onError = () => {
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
      <div className="form-group auth-formGroup">
        <label className="c-label auth-formGroupLabel" htmlFor="email">
          {intl.messages?.forgotPassword?.form.email.label}
        </label>
        <input
          className={`c-input auth-input ${
            errors?.email ? "c-input-email-error" : ""
          } ${email ? "input-filled" : ""}`}
          id="email"
          name="email"
          type="text"
          placeholder={intl.messages?.forgotPassword?.form.email.placeholder}
          {...register("email", { required: true })}
        />
        {errors?.email && (
          <p className="input-v2-error-label">
            <WarningCircle className="input-v2-error-icon" size={16} />
            {errors?.email && intl.messages?.forgotPassword?.form.email.error}
          </p>
        )}
      </div>
      <div className="form-group auth-submit">
        <button
          style={{ background: color, ...buttonStyle }}
          className={`c-button c-button--primary ${
            loading && "c-button--disabled"
          }`}
          type="submit"
          onClick={() => {
            handleSubmit(onSubmit, onError);
          }}
        >
          {loading
            ? intl.messages?.forgotPassword?.form.submit.loading
            : intl.messages?.forgotPassword?.form.submit.default}
        </button>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
