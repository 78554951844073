import React from "react";
import { Trash } from "phosphor-react";
import { getPartThumbnail } from "#/src/utils";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import { Counter } from "./Counter";

const AddedPartToWorkOrder = ({
  part,
  onDeleteClick,
  machine,
  disableDelete = false,
  extraClass,
  incrementCount,
  decrementCount,
  ...props
}) => {
  return (
    <div
      className={"added-part-to-work-order-wrapper u-overflow-hidden"}
      {...props}
    >
      <div className="u-flex added-part-to-work-order-left u-overflow-hidden">
        <div className="added-part-to-work-order__image">
          <img src={getPartThumbnail(part)} />
        </div>
        <div className="added-part-to-work-order__info u-overflow-hidden">
          <BodyText
            size={BODY_TEXT_SIZES.X_SMALL}
            color="text-secondary"
            className="truncate max-lg:items-start max-lg:w-full no-styles"
          >
            {part.articleNumber}
          </BodyText>
          <Label size={LABEL_SIZES.SMALL} className="truncate max-lg:hidden">
            {part.name}
          </Label>
        </div>
      </div>

      <Label size={LABEL_SIZES.SMALL} className="truncate w-full lg:hidden">
        {part.name}
      </Label>
      <div className="u-flex added-part-to-work-order-action">
        <div
          className="create-spare-part-order-modal-part-remove-button"
          onClick={onDeleteClick}
        >
          <Trash size={16} />
        </div>
        <Counter
          countValue={part.quantity}
          incrementCount={incrementCount}
          decrementCount={decrementCount}
          wrapperClassname="spare-parts-order-counter-wrapper"
          decrementButtonClassname="spare-parts-order-counter-decrement"
          incrementButtonClassname="spare-parts-order-counter-increment"
        />
      </div>
    </div>
  );
};

export default AddedPartToWorkOrder;
