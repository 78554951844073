import React from "react";
import { WORK_ORDER_TYPE_ICONS } from "#/src/constants/workOrderTypes";
import ColorPalette from "$/settings/color-palette.json";

import {
  BrokenLinkIcon,
  EnergyIcon,
  IceCrystalIcon,
  InspectionIcon,
  LightBulbIcon,
  OpenFolderIcon,
  PreventiveIcon,
  ProcedureIcon,
  ShieldIcon,
  SpannersIcon,
  HourglassIcon,
  PDFIcon,
} from "~/icons";
import { Wrench } from "phosphor-react";

const WorkOrderTypeIcons = ({ icon, strokeColor, size = 16 }) => {
  switch (icon) {
    case WORK_ORDER_TYPE_ICONS.BROKEN_LINK:
      return (
        <BrokenLinkIcon
          width={size}
          height={size}
          strokeColor={strokeColor || ColorPalette.darkGray.primary}
        />
      );

    case WORK_ORDER_TYPE_ICONS.ENRERGY:
      return (
        <EnergyIcon
          width={size}
          height={size}
          strokeColor={strokeColor || ColorPalette.darkGray.primary}
        />
      );

    case WORK_ORDER_TYPE_ICONS.ICE_CRYSTAL:
      return (
        <IceCrystalIcon
          width={size}
          height={size}
          strokeColor={strokeColor || ColorPalette.darkGray.primary}
        />
      );

    case WORK_ORDER_TYPE_ICONS.INSPECTION:
      return (
        <InspectionIcon
          width={size}
          height={size}
          strokeColor={strokeColor || ColorPalette.darkGray.primary}
        />
      );

    case WORK_ORDER_TYPE_ICONS.LIGHT_BULB:
      return (
        <LightBulbIcon
          width={size}
          height={size}
          strokeColor={strokeColor || ColorPalette.darkGray.primary}
        />
      );

    case WORK_ORDER_TYPE_ICONS.PREVENTIVE:
      return (
        <PreventiveIcon
          width={size}
          height={size}
          strokeColor={strokeColor || ColorPalette.darkGray.primary}
        />
      );

    case WORK_ORDER_TYPE_ICONS.PROCEDURE:
      return (
        <ProcedureIcon
          width={size}
          height={size}
          strokeColor={strokeColor || ColorPalette.darkGray.primary}
        />
      );

    case WORK_ORDER_TYPE_ICONS.SHIELD:
      return (
        <ShieldIcon
          width={size}
          height={size}
          strokeColor={strokeColor || ColorPalette.darkGray.primary}
        />
      );

    case WORK_ORDER_TYPE_ICONS.SPANNERS:
      return (
        <SpannersIcon
          width={size}
          height={size}
          strokeColor={strokeColor || ColorPalette.darkGray.primary}
        />
      );

    case WORK_ORDER_TYPE_ICONS.SPARE_PART_ORDER:
      return (
        <Wrench
          size={size}
          color={strokeColor || ColorPalette.darkGray.primary}
        />
      );

    case WORK_ORDER_TYPE_ICONS.PREVENTIVE_MAINTENANCE:
      return (
        <HourglassIcon
          width={size}
          height={size}
          fillColor={strokeColor || ColorPalette.darkGray.primary}
        />
      );
    case WORK_ORDER_TYPE_ICONS.FORM_SUBMISSION:
      return (
        <PDFIcon
          width={size}
          height={size}
          fillColor={strokeColor || ColorPalette.darkGray.primary}
        />
      );

    default:
      return (
        <OpenFolderIcon
          width={size}
          height={size}
          strokeColor={strokeColor || ColorPalette.darkGray.primary}
        />
      );
  }
};

export default WorkOrderTypeIcons;
