import { Gear } from "phosphor-react";
import * as React from "react";
import Settings from "~/containers/_settings.jsx";

export const settingRoutes = (WithHeader) => {
  return [
    {
      id: "app",
      path: "/app/settings",
      exact: true,
      label: "settings",
      icon: <Gear className="icon" size={20} />,
      iconActive: <Gear className="icon-active" size={20} weight="fill" />,
      showInNavigation: false,
      main: (props) => {
        var header = () => {
          return {
            title: props.intl.messages.header?.settings?.title,
            navigation: props.intl.messages?.navigation?.["settings"],
            backIcon: false,
            headerClass: "desktop-hidden",
          };
        };

        return (
          <WithHeader {...props} header={header()}>
            <Settings {...props} />
          </WithHeader>
        );
      },
    },
  ];
};
