import {
  LARGE_THUMBNAIL_DIMENSIONS,
  THUMBNAIL_DIMENSIONS,
  PART_DEFUALT_THUMBNAIL,
} from "../constants";
export const getPartThumbnail = (part, isPreview = false) => {
  const thumbnailDimensions = isPreview
    ? LARGE_THUMBNAIL_DIMENSIONS
    : THUMBNAIL_DIMENSIONS;
  if (part?.image) {
    return `${part?.image}${thumbnailDimensions}`;
  } else {
    return PART_DEFUALT_THUMBNAIL;
  }
};
