import { useQuery } from "@apollo/client";
import { LIST_PREVENTIVE_MAINTENANCE_EVENTS } from "../../api";

/**
 *
 * @param query should contain an object with at least a key "where" and it's also an object with some specifice keys
 * See more details on this link: https://www.npmjs.com/package/mongo-search-parameters
 * @returns [PreventiveMaintenance] | []
 */
export const listAllPreventiveMaintenanceEvents = (query) => {
  const { data: { listPreventiveMaintenanceEvents } = {}, loading } = useQuery(
    LIST_PREVENTIVE_MAINTENANCE_EVENTS,
    {
      variables: {
        params: query,
      },
    },
  );

  return {
    loading,
    events: listPreventiveMaintenanceEvents,
  };
};
