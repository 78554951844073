import React, { useMemo, useState } from "react";

import Search from "~/components/Search";
import SingleEvent from "./SingleEvent";

const PreventiveMaintenance = ({ events, machineId }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchEvent = (value) => {
    setSearchTerm(value);
  };

  const filteredEvents = useMemo(
    () =>
      events?.filter((event) =>
        event.title.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    [searchTerm],
  );

  return (
    <div>
      <div className="preventive-maintenance-header">
        <Search size="full" placeholder="Search" onChange={handleSearchEvent} />
      </div>

      <div className="preventive-maintenance-content">
        {filteredEvents?.map((evt) => {
          return (
            <div key={evt._id} className="preventive-maintenance-event-wrapper">
              <SingleEvent
                key={evt._id}
                title={evt.title}
                startDate={evt.startDate}
                repeatInNumber={evt.repeatInNumber}
                repeatIn={evt.repeatIn}
                eventDate={evt.eventDate}
                description={evt.description}
                frequency={evt.frequency}
                nextCreationDate={evt.nextCreationDate}
                ticketCreationNumber={evt.ticketCreationNumber}
                ticketCreationIn={evt.ticketCreationIn}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PreventiveMaintenance;
