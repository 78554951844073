import { atom } from "recoil";

export const MachinesAtom = atom({
  key: "MachinesAtom",
  default: {
    totalMachines: 0,
    search: {
      value: "",
    },
  },
});
