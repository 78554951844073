import React from "react";
import { List } from "phosphor-react";
import { useRecoilState } from "recoil";
import { navigationAtom } from "~/components/_navigation";

const Hamburger = () => {
  const [navigationOptions, setNavigationOptions] =
    useRecoilState(navigationAtom);

  const onHanburgerClick = () => {
    setNavigationOptions({
      ...navigationAtom,
      isCollapsed: false,
      isResponsiveCollapsed: !navigationOptions.isResponsiveCollapsed,
    });
  };

  return (
    <div
      className="mobile-element u-inline-flex u-cursor-pointer hamburger-toggler"
      onClick={onHanburgerClick}
    >
      <List size={24} weight="bold" />
    </div>
  );
};

export default Hamburger;
