import React from "react";
import { useIntl } from "react-intl";
import BoxUIElement from "~/components/boxUIElement";
import { DeleteTrashImage } from "~/icons";
import { NotFoundComponent } from "~/components/_notFoundMessage";
import ContentLoading from "~/components/ContentLoading";

const PartDocuments = ({ userFolderToken, part }) => {
  const { messages } = useIntl();

  const hasNoPartDocuments = !part?.documentFolders?.externalId;

  const showDocumentsForPart = part?.documentFolders?.externalId;

  const isCreatingBoxFolderIds = () => {
    return !part?.documentFolders?.externalId;
  };

  return (
    <>
      {isCreatingBoxFolderIds() ? (
        <ContentLoading />
      ) : (
        <>
          {showDocumentsForPart ? (
            <>
              <BoxUIElement
                key={part.documentFolders?.externalId}
                token={userFolderToken}
                entityId={part.documentFolders?.externalId}
                rootFolderId={part.documentFolders?.externalId}
                entityName={part?.name}
                canUpload={false}
                canRename={false}
                canDelete={false}
                canCreateNewFolder={false}
              />
            </>
          ) : null}

          {hasNoPartDocuments ? (
            <NotFoundComponent
              key="not-found-any-machine-doc"
              icon={<DeleteTrashImage />}
              title={
                messages?.tickets?.ticketDetails.ticketOverviewTab.noDocuments
              }
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default PartDocuments;
