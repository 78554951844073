import {
  LIST_OWN_CUSTOMER_COMPONENTS_QUERY,
  GET_OWN_CUSTOMER_COMPONENT_BY_ID_QUERY,
  GET_OWN_CUSTOMER_COMPONENT_PARTS_BY_ID_QUERY,
} from "#/src/api";
import { ITEMS_PER_PAGE } from "#/src/constants/global";
import { useQuery } from "@apollo/client";

export const getComponentsByParams = ({
  where,
  limit = ITEMS_PER_PAGE,
  skip = 0,
}) => {
  const variables = {
    params: {
      ...(where ? { where } : {}),
      limit,
      skip,
    },
  };

  const {
    data: { listOwnCustomerComponents = {} } = {},
    loading,
    fetchMore,
    error,
  } = useQuery(LIST_OWN_CUSTOMER_COMPONENTS_QUERY, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const handleFetchMore = ({ limit, skip }) => {
    fetchMore({
      variables: {
        ...variables,
        params: {
          ...variables.params,
          limit,
          skip,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedComponents = [
          ...previousResult.listOwnCustomerComponents.components,
          ...fetchMoreResult.listOwnCustomerComponents.components,
        ];

        const updates = {
          ...previousResult,
          listOwnCustomerComponents: {
            ...previousResult.listOwnCustomerComponents,
            ...fetchMoreResult.listOwnCustomerComponents,
            components: mergedComponents,
          },
        };

        return updates;
      },
    });
  };

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    components = [],
  } = listOwnCustomerComponents ?? {
    currentPage: 0,
    limit: ITEMS_PER_PAGE,
    skip: 0,
    totalCount: 0,
    components: [],
  };

  return {
    components,
    loading,
    error,
    handleFetchMore,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
  };
};

export const getComponentById = (id) => {
  const {
    data: { getOwnCustomerComponentById: component = {} } = {},
    loading,
  } = useQuery(GET_OWN_CUSTOMER_COMPONENT_BY_ID_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  });

  return [component, loading];
};

export const getComponentPartsById = (id) => {
  const {
    data: { getOwnCustomerComponentById: component = {} } = {},
    loading,
  } = useQuery(GET_OWN_CUSTOMER_COMPONENT_PARTS_BY_ID_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  });

  return [component?.inventoryParts, loading];
};
