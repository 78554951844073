import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

export const Tabs = ({
  children,
  currentTab = undefined,
  className = "",
  useHistoryState = true,
}) => {
  const history = useHistory();
  const [activeTab, setActiveTab] = React.useState(
    (useHistoryState && history.location.state?.activeTab) || currentTab || 0,
  );

  const tabs = React.useMemo(
    () => React.Children.toArray(children)?.filter((item) => !!item),
    [children],
  );

  useEffect(() => {
    if (currentTab !== undefined) setActiveTab(currentTab);
  }, [currentTab]);

  return (
    <div className={`tabs-wrapper ${className}`}>
      <div className="tab-header">
        <ul className="tabs-list">
          {tabs?.map((tab, index) => (
            <React.Fragment key={index}>
              <TabTitle
                activeTab={activeTab}
                index={index}
                setActiveTab={setActiveTab}
                title={tab?.props?.title}
                useHistoryState={useHistoryState}
                {...tab?.props}
              />
            </React.Fragment>
          ))}
        </ul>
      </div>
      <div className="tab-content-wrapper">{tabs[activeTab]}</div>
    </div>
  );
};

export const Tab = ({ children, className, ...props }) => {
  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};

const TabTitle = ({
  title,
  setActiveTab,
  index,
  activeTab,
  currentTab,
  restricted,
  onRestrictedTabClick,
  path,
  useHistoryState,
  ...props
}) => {
  const history = useHistory();

  const handleClick = useCallback(() => {
    if (restricted) {
      if (onRestrictedTabClick) {
        onRestrictedTabClick();
      }
      return;
    }
    setActiveTab(index);
    useHistoryState &&
      history.replace({
        ...history,
        state: { ...history.location.state, activeTab: index },
      });
    if (path) {
      history.push(path);
    }
  }, [setActiveTab, index]);

  return (
    <li
      {...props}
      className={`tab-list-item${activeTab === index ? " active" : ""}`}
    >
      <button onClick={handleClick}>{title}</button>
    </li>
  );
};
