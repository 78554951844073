import React from "react";
import ContentLoader from "react-content-loader";

export const TicketLoaderComponent = ({
  total = 2,
  width = 1154,
  height = 96,
  ...props
}) => {
  const loaders = [];
  for (let i = 0; i < total; i++) {
    loaders.push(
      <ContentLoader
        speed={1.5}
        width={800}
        height={172}
        viewBox="0 0 800 172"
        backgroundcolor="#ededed"
        foregroundcolor="#dbdbdb"
        {...props}
      >
        <circle cx="44" cy="55" r="33" />
        <rect x="93" y="35" rx="0" ry="0" width="124" height="10" />
        <rect x="94" y="59" rx="0" ry="0" width="88" height="9" />
        <rect x="356" y="35" rx="0" ry="0" width="76" height="8" />
        <rect x="357" y="56" rx="0" ry="0" width="57" height="8" />
        <rect x="564" y="36" rx="0" ry="0" width="50" height="14" />
      </ContentLoader>,
    );
  }

  return loaders;
};
