import { useMemo } from "react";
import { useAuth } from "../components/general";

const useGetClosedStatus = () => {
  const {
    user: { oem },
  } = useAuth();

  const closedStatus = useMemo(
    () => oem?.statuses?.[oem.statuses.length - 1],
    [oem?.statuses],
  );

  return { closedStatus };
};

export default useGetClosedStatus;
