import { faker } from "@faker-js/faker";
import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_INVENTORY_PART } from "~/api";
// create fake part
const createFakePart = () => {
  return {
    _id: faker.random.alphaNumeric(30),
    name: faker.lorem.words(Math.ceil(Math.random() * 3)),
    articleNumber: faker.internet.mac(),
    description: faker.lorem.lines(),
    image: faker.image.imageUrl(160, 120),
    thumbnail: faker.image.imageUrl(60, 45),
  };
};

// all parts under OEM
export const getAllParts = () => {
  const allParts = [];

  Array.from({ length: 10 }).forEach(() => {
    allParts.push(createFakePart());
  });

  return allParts;
};

// all parts added to Machine
export const getMachineParts = (machineId) => {
  const machineParts = [];

  Array.from({ length: 10 }).forEach(() => {
    machineParts.push(createFakePart());
  });

  return machineParts;
};

export const getCustomerInventoryPart = (id) => {
  const {
    data: { getCustomerInventoryPart } = {},
    loading,
    refetch,
  } = useQuery(GET_CUSTOMER_INVENTORY_PART, {
    variables: {
      id,
    },
    skip: !id,
  });

  return {
    loading,
    inventoryPart: getCustomerInventoryPart,
    refetch,
  };
};
