import React from "react";
import { useSlate } from "slate-react";
import { Button } from "./Button";
import { isMarkActive, toggleMark } from "./utils";

export const LeafButton = ({ format, icon, readOnly }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        if (readOnly) return;
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <span>{icon}</span>
    </Button>
  );
};
