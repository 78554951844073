export default [
  {
    id: 1,
    locale: "en-US",
    isoLocale: "en-US",
    language: "en",
    name: "English",
    dir: "ltr",
    label: "en",
  },
  {
    id: 2,
    locale: "de-DE",
    isoLocale: "de-DE",
    language: "de",
    name: "German",
    dir: "ltr",
    label: "de",
  },
  {
    id: 3,
    locale: "it-IT",
    isoLocale: "it-IT",
    language: "it",
    name: "Italian",
    dir: "ltr",
    label: "ita",
  },
];
