import { NoUserAssignedIcon } from "#/src/icons";
import React from "react";
import { NotFoundComponent } from "../_notFoundMessage";
import MobileSearch from "../header/MobileSearch";
import ComponentListItem from "./ComponentListItem";
import { ITEMS_BEFORE_PAGE, ITEMS_PER_PAGE } from "#/src/constants/global";
import { Waypoint } from "react-waypoint";
import Spinner from "../_spinner";
import { MachineLoaderComponent } from "../skeletonLoaders/_machineLoders";

const ComponentsTab = ({
  components = [],
  intl,
  machine,
  loading,
  handleFetchMore,
  totalCount,
  setSearchTerm,
  searchTerm,
}) => {
  const handleScrollToBottom = (event) => {
    if (
      components.length >= ITEMS_PER_PAGE &&
      !loading &&
      event?.previousPosition !== "above" &&
      components.length < totalCount
    ) {
      handleFetchMore({
        limit: ITEMS_PER_PAGE,
        skip: components.length,
      });
    }
  };

  return (
    <>
      <MobileSearch
        onChange={(value) => setSearchTerm(value)}
        value={searchTerm}
        className="production-line-machines-search"
        isMobileElement={false}
        placeholder={
          intl?.messages?.machines?.machineDetails?.componentsTab
            ?.searchPlaceholder
        }
      />
      {loading && !components.length && <Spinner />}
      {Boolean(!loading && !searchTerm && totalCount === 0) && (
        <NotFoundComponent
          icon={<NoUserAssignedIcon />}
          title={
            intl?.messages?.machines?.machineDetails?.componentsTab
              ?.noComponentsFound
          }
        />
      )}
      {Boolean(searchTerm && !components?.length && !loading) && (
        <NotFoundComponent
          icon={<NoUserAssignedIcon />}
          title={intl.messages?.common?.noResultFound}
        />
      )}
      {components?.map((component, index) => (
        <React.Fragment key={component._id}>
          <ComponentListItem
            intl={intl}
            machineId={machine?._id}
            component={component}
          />
          {index === components.length - ITEMS_BEFORE_PAGE && (
            <Waypoint onEnter={handleScrollToBottom} />
          )}
        </React.Fragment>
      ))}
      {loading && <MachineLoaderComponent total={1} />}
    </>
  );
};

export default ComponentsTab;
