import { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import download from "downloadjs";

// mutation
const _signS3DownloadMutation = gql`
  mutation ($filename: String!) {
    _signS3Download(filename: $filename) {
      url
      signedRequest
    }
  }
`;

export default () => {
  const [s3SignDownload] = useMutation(_signS3DownloadMutation);
  const [isDownloading, setIsDownloading] = useState(false);
  const [whichUrlIsDownloading, setWhichUrlIsDownloading] = useState(false);

  const downloadFile = async (url) => {
    const filename = new URL(url).pathname.replace("/", "");
    const response = await s3SignDownload({
      variables: {
        filename,
      },
    });
    const { signedRequest } = response.data._signS3Download;
    setIsDownloading(true);
    setWhichUrlIsDownloading(url);
    try {
      fetch(signedRequest)
        .then((response) => {
          return response.blob().then((raw) => raw);
        })
        .then(async (data) => {
          const readableFilename = filename.split("___")?.pop();
          download(data, readableFilename);
          setIsDownloading(false);
          setWhichUrlIsDownloading(null);
        });
    } catch (e) {
      setIsDownloading(false);
      setWhichUrlIsDownloading(null);
    }
  };
  return {
    isDownloading,
    download: downloadFile,
    whichUrlIsDownloading,
  };
};
