import React from "react";

const NotFoundMessage = ({ className, text, variant = "" }) => {
  const classes = [
    `c-notFoundMessage ${className}`,
    variant ? `c-notFoundMessage--${variant}` : "",
  ].join(" ");

  return (
    <div className={classes}>
      <p className="c-notFoundMessageText">{text}</p>
    </div>
  );
};

export const NotFoundComponent = ({ title, icon }) => {
  return (
    <div className="no-tickets-wrapper full-page">
      <span className="no-tickets-icon">{icon || <NoTicketsLargeIcon />}</span>

      <p className="no-tickets-title">{title || "Not found"}</p>
    </div>
  );
};

export default NotFoundMessage;
