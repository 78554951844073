import client from "../../apollo/_client";
import { GET_OWN_CUSTOMER_MACHINE } from "~/api";

export const updateMachineCache = (payload) => {
  const machine = client.readQuery({
    query: GET_OWN_CUSTOMER_MACHINE,
    variables: { id: payload._id },
  });

  if (!machine) return;

  const { getOwnCustomerMachineById: cachedMachine } = machine;
  if (cachedMachine) {
    const cloned = { ...cachedMachine };
    cloned.documentFolders = {
      ...(cloned.documentFolders ? cloned.documentFolders : {}),
      ...payload.documentFolders,
    };

    client.writeQuery({
      query: GET_OWN_CUSTOMER_MACHINE,
      variables: { id: payload._id },
      data: {
        getOwnCustomerMachineById: cloned,
      },
    });
  }
};
