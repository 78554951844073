import React, { useState } from "react";

const ChatIndicator = ({
  active,
  messages,
  messagesContainer,
  channelId,
  instance,
}) => {
  const [counts] = useState({});

  const scrollToBottom = () => {
    messagesContainer.scrollTop = messagesContainer.scrollHeight;
  };

  return (
    <span
      onClick={scrollToBottom}
      className={`c-chat-scrollButton ${
        active && "c-chat-scrollButton--active"
      } `}
    >
      {counts?.channelId !== 0 ? (
        counts[channelId]
      ) : (
        <i className="fas fa-arrow-down"></i>
      )}
    </span>
  );
};

export default ChatIndicator;
