import isHotkey from "is-hotkey";
import {
  ListBullets,
  ListNumbers,
  TextBolder,
  TextItalic,
  TextUnderline,
  TextStrikethrough,
  TextAlignLeft,
  TextAlignCenter,
  TextAlignRight,
} from "phosphor-react";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import { Editable, withReact, Slate } from "slate-react";

import { BlockButton } from "./BlockButton";
import { ElementRenderer } from "./elementRenderer";
import { LeafButton } from "./LeafButton";
import { LeafRenderer } from "./leafRenderer";
import { toggleMark } from "./utils";

import { TEXT_EDITOR_MAX_HEIGHT } from "#/src/constants";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+s": "strikethrough",
};

const TextEditor = ({
  readOnly = false,
  content,
  onUpdate = null,
  placeholder,
  autoFocus,
  className,
  setIsScrolling,
  mainEditorClassName = "",
  ...props
}) => {
  // Element Renderer
  const renderElement = useCallback(
    (props) => <ElementRenderer {...props} />,
    [],
  );

  // Leaf Renderer
  const renderLeaf = useCallback((props) => <LeafRenderer {...props} />, []);

  // Editor
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const editableRef = useRef(null);

  // Prepare Editor Formatted Data
  const preparedContent = useMemo(() => {
    let parsedData = [
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ];
    if (!content) {
      return parsedData;
    }
    try {
      const parsedString = JSON.parse(content);
      parsedData = parsedString === "" ? parsedData : parsedString;
    } catch (e) {
      parsedData = [
        {
          type: "paragraph",
          children: [{ text: content }],
        },
      ];
    }
    return parsedData;
  }, [content]);

  useEffect(() => {
    if (editableRef?.current?.offsetHeight > TEXT_EDITOR_MAX_HEIGHT)
      setIsScrolling?.(true);
  }, [editableRef?.current]);

  const onChange = (value) => {
    if (readOnly) return;
    onUpdate(value);
  };

  return (
    <Slate editor={editor} value={preparedContent} onChange={onChange}>
      <div
        className={`editor-wrapper ${readOnly ? "readonly" : ""} ${
          className || ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          if (readOnly && onUpdate) {
            onUpdate();
          }
        }}
        {...props}
      >
        {!readOnly && (
          <div className="editor-toolbar">
            <BlockButton
              format="numbered-list"
              icon={<ListNumbers size={20} />}
              readOnly={readOnly}
            />
            <BlockButton
              format="bulleted-list"
              icon={<ListBullets size={20} />}
              readOnly={readOnly}
            />
            <BlockButton
              format="left"
              icon={<TextAlignLeft size={20} />}
              readOnly={readOnly}
            />

            <BlockButton
              format="right"
              icon={<TextAlignRight size={20} />}
              readOnly={readOnly}
            />
            <BlockButton
              format="center"
              icon={<TextAlignCenter size={20} />}
              readOnly={readOnly}
            />
            <LeafButton
              format="strikethrough"
              icon={<TextStrikethrough size={20} />}
              readOnly={readOnly}
            />
            <LeafButton
              format="italic"
              icon={<TextItalic size={20} />}
              readOnly={readOnly}
            />
            <LeafButton
              format="bold"
              icon={<TextBolder size={20} weight="bold" />}
              readOnly={readOnly}
            />
            <LeafButton
              format="underline"
              icon={<TextUnderline size={20} />}
              readOnly={readOnly}
            />
          </div>
        )}
        <div
          className={`main-editor max-h-[150px] overflow-y-auto ${mainEditorClassName}`}
        >
          <div ref={editableRef}>
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder={placeholder || "Enter your text…"}
              autoFocus={autoFocus}
              spellCheck={false}
              readOnly={readOnly}
              onKeyDown={(event) => {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event)) {
                    event.preventDefault();
                    const mark = HOTKEYS[hotkey];
                    toggleMark(editor, mark);
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    </Slate>
  );
};

export default TextEditor;
