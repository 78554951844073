import { X } from "phosphor-react";
import React from "react";
import ReactDOM from "react-dom";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";

export const Drawer = ({
  children,
  isOpen,
  title,
  onClose,
  submitButtonText = "submit",
  cancelButtonText,
  onSubmit,
  onCancel,
  overlay = true,
  submitButtonDisabled = true,
  cancelButtonDisabled = true,
  hideFooter = false,
}) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={`drawer-bg-overlay${overlay ? " has-overlay" : ""}`}>
      <div className="drawer">
        <div className="drawer-header">
          {title && <h2 className="drawer-header-title">{title}</h2>}
          <button
            onClick={onClose}
            className="drawer-close-button btn-v2 ghost-icon-btn-v2 only-icon-btn-v2"
          >
            <X size={20} />
          </button>
        </div>

        <div className="drawer-content">{children}</div>

        {!hideFooter && (
          <div className="drawer-footer">
            {cancelButtonText && (
              <button
                onClick={onCancel}
                className={"btn-v2 btn-v2-full ghost-btn-v2"}
                disabled={submitButtonDisabled}
              >
                {cancelButtonText}
              </button>
            )}

            <Button
              variant={BUTTON_VARIANTS.PRIMARY}
              text={submitButtonText}
              fullWidth
              disabled={submitButtonDisabled}
              onClick={onSubmit}
            />
          </div>
        )}
      </div>
    </div>,
    document.body,
  );
};
