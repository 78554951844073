export { base64String } from "./encode";
export { saveLocaleToStorage, getLocaleFromStorage } from "./_storage";
export { default as getEnums } from "$/settings/enums";
export { generateInitials, trimText } from "./_initials";

export function getUrlExtension(url) {
  if (typeof url === "undefined") {
    return "";
  }

  return url.split(/[#?]/)[0].split(".").pop().trim();
}

export const maxWaitTimePromise = async (timeoutInMilliseconds = 1000) => {
  return new Promise(function (resolve) {
    setTimeout(() => resolve({ isTimeOut: true }), 60 * timeoutInMilliseconds); // this will resolve after 1 min
  });
};

export const formatDate = (givenDate) => {
  const date = new Date(givenDate);

  if (date.toString() === "Invalid Date") return "";

  const fullYear = date.getFullYear();
  const fullDate = String(date.getDate()).padStart(2, "0");
  const fullMonth = String(date.getMonth() + 1).padStart(2, "0");

  return `${fullDate}-${fullMonth}-${fullYear}`;
};

export const isTextEllipsized = (element) => {
  if (!element) {
    return false;
  }

  return (
    element.offsetWidth < element.scrollWidth ||
    element.offsetHeight < element.scrollHeight
  );
};

export const isTrackingDisabled = () => {
  if (sessionStorage.getItem("disableTracking")) {
    return true;
  } else {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const disableTracking = urlSearchParams.get("disableTracking");
    if (disableTracking) {
      sessionStorage.setItem("disableTracking", true);
      return true;
    }
  }
  return false;
};

export * from "./_colors";
export * from "./_components";
export * from "./_machines";
export * from "./_parts";
