// eslint-disable-next-line no-use-before-define
import React from "react";
import "./ReloadPrompt.css";

import { useRegisterSW } from "virtual:pwa-register/react";

function ReloadPrompt() {
  // replaced dynamically
  const buildDate = "__DATE__";
  // replaced dyanmicaly
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {},
  });

  const close = () => {
    setOfflineReady(false);
    setNeedRefresh(false);
  };
  if (!needRefresh) return null;

  return (
    <div className="ReloadPrompt-container">
      <div className="ReloadPrompt-toast">
        <div className="ReloadPrompt-message">
          <span>New content available, click on reload button to update.</span>
        </div>
        <button
          className="ReloadPrompt-toast-button"
          onClick={() => updateServiceWorker(true)}
        >
          Reload
        </button>
        <button className="ReloadPrompt-toast-button" onClick={() => close()}>
          Close
        </button>
      </div>
      <div className="ReloadPrompt-date">{buildDate}</div>
    </div>
  );
}

export default ReloadPrompt;
