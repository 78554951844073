import React, { useState, useEffect, useMemo } from "react";
import { Tab, Tabs } from "../components/_tabs";
import { getMachinesByParams } from "../services";
import MachinesListing from "~/containers/_machinesListing.jsx";
import AppHeader from "../components/header/AppHeader";
import { useIntl } from "react-intl";
import Hamburger from "../components/header/Hamburger";
import MobileSearch from "../components/header/MobileSearch";
import Search from "../components/Search";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import ProductionLinesListing from "./_productionLineslisting";
import { getProuctionLines } from "../services/productionLine";
import { useAuth } from "../components/general";
import PAID_FEATURES from "$/settings/paid-features.json";
import { getEnums } from "../utils";
import { useHistory } from "react-router-dom";

const paidFeaturesRef = getEnums(PAID_FEATURES, "reference");

const Assets = ({ intl, header }) => {
  const { messages } = useIntl();
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const history = useHistory();
  const { user } = useAuth();

  const isProductionLinePaid = user?.oem?.paidFeatures.includes(
    paidFeaturesRef.productionLines,
  );

  const isProductionLinesTabActive = isProductionLinePaid && activeTab === 0;

  const {
    machines = [],
    loading,
    totalCount,
    handleFetchMore,
  } = getMachinesByParams({
    where: {
      searchQuery: isProductionLinesTabActive ? "" : searchValue,
    },
  });

  const {
    productionLines,
    loading: productionLinesLoading,
    totalCount: productionLinesTotalCount,
    handleFetchMore: handleFetchMoreProductionLines,
  } = getProuctionLines({
    where: {
      searchQuery: isProductionLinesTabActive ? searchValue : "",
    },
  });

  useEffect(() => {
    const path = history.location.pathname;
    if (isProductionLinePaid) {
      if (path.endsWith("production-lines")) {
        setActiveTab(0);
      } else if (path.endsWith("machines")) {
        setActiveTab(1);
      } else if (path.endsWith("assets")) {
        setActiveTab(0);
        history.replace(`/${intl?.locale}/app/assets/production-lines`);
      }
    } else if (path.endsWith("assets") || path.endsWith("production-lines")) {
      history.replace(`/${intl?.locale}/app/assets/machines`);
    }
  }, [history.location.pathname]);

  const onSearch = (value) => setSearchValue(value);

  return (
    <>
      <AppHeader className="u-padding-y-3 u-items-center container-space-margin">
        <Headline size={HEADLINE_SIZES.SMALL}>
          {messages?.header.machines.title}
        </Headline>
        <div className="u-flex u-items-center desktop-element">
          <Search
            onChange={onSearch}
            content={searchValue}
            placeholder={
              isProductionLinesTabActive
                ? messages?.productionLines.searchPlaceHolder
                : messages?.machines?.searchPlaceholder
            }
          />
        </div>
        {/* Hamburger component is only for mobile view */}
        <Hamburger />
      </AppHeader>
      <div className="assets-divider" />
      {/* MobileSearch component is only for mobile view */}
      <MobileSearch onChange={onSearch} value={searchValue} />
      <div className="assets-tabs-wrapper u-width-100">
        <div className="page-details-tab">
          <Tabs currentTab={activeTab}>
            {isProductionLinePaid && (
              <Tab
                title={`${messages?.productionLines?.title} (${
                  productionLinesTotalCount ?? 0
                })`}
                path={`/${intl?.locale}/app/assets/production-lines`}
              >
                <ProductionLinesListing
                  hasProductionLines={Boolean(productionLines?.length)}
                  productionLines={productionLines}
                  intl={intl}
                  loading={productionLinesLoading}
                  totalCount={productionLinesTotalCount}
                  handleFetchMore={handleFetchMoreProductionLines}
                />
              </Tab>
            )}
            <Tab
              title={`${messages?.machines?.title} (${totalCount ?? 0})`}
              path={`/${intl?.locale}/app/assets/machines`}
            >
              <MachinesListing
                intl={intl}
                machines={machines}
                loading={loading}
                totalCount={totalCount}
                handleFetchMore={handleFetchMore}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Assets;
