import { useEffect, useState, useRef } from "react";

const useDetermineDropdownPos = (
  ddRef,
  ddHeight,
  defaultPos,
  scrollContainerSelector,
) => {
  const mounted = useRef(true);
  const [dropPos, setDropPos] = useState(defaultPos);

  const determineDropUp = () => {
    if (defaultPos !== "auto") return;
    const node = ddRef.current;

    if (!node) {
      if (mounted.current) {
        setDropPos("bottom");
      }
      return;
    }

    const windowHeight = window.innerHeight;
    const calendarHeight = ddHeight;
    const nodeBottomPos = node.getBoundingClientRect().bottom;
    const position =
      nodeBottomPos + calendarHeight >= windowHeight ? "top" : "bottom";

    if (mounted.current) {
      setDropPos(position);
    }
  };

  useEffect(() => {
    mounted.current = true;
    const scrollContainerElement = scrollContainerSelector
      ? document.querySelector(scrollContainerSelector)
      : null;

    determineDropUp();
    window.addEventListener("resize", determineDropUp);
    window.addEventListener("scroll", determineDropUp);

    if (scrollContainerElement) {
      scrollContainerElement.addEventListener("scroll", determineDropUp);
    }

    return () => {
      mounted.current = false;
      window.removeEventListener("resize", determineDropUp);
      window.removeEventListener("scroll", determineDropUp);

      if (scrollContainerElement) {
        scrollContainerElement.removeEventListener("scroll", determineDropUp);
      }
    };
  }, []);

  return dropPos;
};

export default useDetermineDropdownPos;
