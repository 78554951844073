import React, { useState, useEffect } from "react";

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { atom, useRecoilState } from "recoil";

import { ToastContainer } from "react-toastify";
import { IntlProvider } from "react-intl";

import "react-toastify/dist/ReactToastify.min.css";

import App from "./App";

import { Login, QrcMachineLogin } from "./components";
import { ErrorBoundary, ErrorPage } from "./components/general";

import ROUTES_SETTINGS from "$/settings/routes-resolvers.json";
import { getLocaleFromStorage } from "~/utils";

import appLanguageResources from "./lang";
import locales from "./config/locales";
import { InfoPageFull } from "~/components/general/_infoPage";

import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/it";

import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/it";
import ForgotPassword from "~/containers/_forgotPassword";
import ForgotPasswordSuccess from "~/containers/_forgotPasswordSuccess";
import AuthPageGuard from "./components/general/AuthPageGuard";

const { CLIENT: { ROUTES: { FACILITY_LOGIN: LOGIN } = {} } = {} } =
  ROUTES_SETTINGS;

const { pathname } = window.location;

let defaultLocale = locales[0];

locales.forEach((locale) => {
  if (
    pathname === `/${locale.language}` ||
    pathname.startsWith(`/${locale.language}/`)
  ) {
    defaultLocale = locale;
  }
});

export const languageAtom = atom({
  key: "languageOption",
  default: {
    locale: getLocaleFromStorage() || defaultLocale,
  },
});

const LocaleHandler = () => {
  const [languageOption] = useRecoilState(languageAtom);
  const [locale, setLocale] = useState(languageOption.locale);

  useEffect(() => {
    setLocale(languageOption.locale);
  }, [languageOption.locale]);

  return (
    <IntlProvider
      locale={locale.language}
      defaultLocale={defaultLocale.language}
      messages={appLanguageResources[locale.language]}
    >
      <ErrorBoundary>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path={`/:lang${LOGIN}/:modifier?`}
              component={() => (
                <AuthPageGuard>
                  <Login />
                </AuthPageGuard>
              )}
            />
            <Route
              exact
              path={"/m/:machineUuid"}
              component={() => <QrcMachineLogin />}
            />
            <Route
              exact
              path={"/qr-error"}
              component={() => (
                <InfoPageFull message="This machine has been unassigned by the provider. Please contact with your provider" />
              )}
            />
            <Route
              exact
              path={"/:lang/forgot-password/:modifier?"}
              component={() => (
                <AuthPageGuard>
                  <ForgotPassword />
                </AuthPageGuard>
              )}
            />
            <Route
              exact
              path={"/:lang/forgot-password-success/:modifier?"}
              component={() => (
                <AuthPageGuard>
                  <ForgotPasswordSuccess />
                </AuthPageGuard>
              )}
            />
            <Route
              exact
              path={"/:lang"}
              render={() => <Redirect to={`/${locale.language}/app`} />}
            />
            <Route
              exact
              path="/:lang?"
              render={() => <Redirect to={`/${locale.language}/app`} />}
            />
            <Route path={"/:lang/app"} component={App} />
            <Route
              exact
              path={"/:lang/error-page/:error"}
              component={ErrorPage}
            />
            {locale.language && <Route component={ErrorPage} />}
          </Switch>
          <ToastContainer
            className="toast-container message-container"
            toastClassName="message"
            draggable={false}
            hideProgressBar
            autoClose={5000}
            position="bottom-right"
          />
        </BrowserRouter>
      </ErrorBoundary>
    </IntlProvider>
  );
};

export default LocaleHandler;
