import * as React from "react";
import createReactClass from "create-react-class";
import { useHandleBoxToken } from "~/hooks";
import { useIntl } from "react-intl";

const BoxClass = createReactClass({
  componentDidMount: function () {
    var contentExplorer = new Box.ContentExplorer();
    // Show the content explorer
    contentExplorer.show(this.props.rootFolderId, this.props.token, {
      container: `.content-explorer___${this.props.entityId}`,
      contentUploaderProps: {
        isFolderUploadEnabled: true,
        fileLimit: 500,
      },
      canShare: false,
      logoUrl: "",
      canDelete:
        this.props.canDelete === undefined ? true : this.props.canDelete,
      canRename:
        this.props.canRename === undefined ? true : this.props.canRename,
      canUpload:
        this.props.canUpload === undefined ? true : this.props.canUpload,
      canCreateNewFolder:
        this.props.canCreateNewFolder === undefined
          ? true
          : this.props.canCreateNewFolder,
      responseInterceptor: (response) => {
        const { data } = response;
        if (data.name && !data.parent?.etag && !data.parent?.sequence_id) {
          data.name = this.props.entityName;
        }

        if (data.modified_by) {
          data.modified_by = {};
        }

        if (data?.item_collection?.entries) {
          data.item_collection.entries = data.item_collection.entries.map(
            (item) => {
              if (item.modified_by) {
                item.modified_by = {};
              }

              if (item.parent.name) {
                item.parent.name = this.props.entityName;
              }

              if (item.path_collection?.entries[1]?.name) {
                item.path_collection.entries[1].name = this.props.entityName;
              }

              return item;
            },
          );
        }

        if (data.entries) {
          data.entries = data.entries.map((item) => {
            if (item.modified_by) {
              item.modified_by = {};
            }

            if (item.parent.name) {
              item.parent.name = this.props.entityName;
            }

            if (item.path_collection?.entries[1]?.name) {
              item.path_collection.entries[1].name = this.props.entityName;
            }

            return item;
          });
        }

        if (data.path_collection?.entries[1]?.name) {
          data.path_collection.entries[1].name = this.props.entityName;
        }

        return response;
      },
    });
  },
  render: function () {
    return (
      <div
        className={`content-explorer___${this.props.entityId} box-documents-container`}
      ></div>
    );
  },
});

export default (props) => {
  const { messages } = useIntl();
  const { token, loading } = useHandleBoxToken();
  if (!token || loading)
    return (
      <p className="text-sm">{messages?.machines?.folderConnecting}....</p>
    );
  return <BoxClass key={token} {...props} token={token} />;
};
