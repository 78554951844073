import { gql } from "@apollo/client";

import { User, Ticket } from "./_fragments";

// REMOTE
export const LOGIN_MUTATION = gql`
  mutation loginFacilityApp($userCredentials: userFacilityCredentials!) {
    loginFacilityApp(input: $userCredentials)
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const UPDATE_SELF_FACILTY_USER_MUTATION = gql`
  mutation updateSelfFacilityUser($input: InputUpdateSelfFacilityUser!) {
    updateSelfFacilityUser(input: $input) {
      ...UserBasicData
    }
  }
  ${User.fragments.UserBasicData}
`;

export const CREATE_OWN_TICKET_MUTATION = gql`
  mutation createOwnTicket($input: InputCreateOwnTicket!) {
    createOwnTicket(input: $input) {
      ...TicketListData
    }
  }
  ${Ticket.fragments.TicketListData}
`;

export const MACHINE_QRC_FACIITY_VIEW_MUTATION = gql`
  mutation machineQrcFacilityView($qrcCredentials: qrcMachineCredentials!) {
    machineQrcFacilityView(input: $qrcCredentials)
  }
`;

export const MACHINE_QRC_FACILITY_VIEW_MUTATION = gql`
  mutation machineQrcFacilityView($qrcCredentials: qrcMachineCredentials!) {
    machineQrcFacilityView(input: $qrcCredentials)
  }
`;

export const UPDATE_PERSONAL_SETTINGS = gql`
  mutation updatePersonalSettings(
    $personalSettings: InputUpdatePersonalSettings
    $emailNotificationSettings: InputEmailNotificationSettings
  ) {
    updatePersonalSettings(
      personalSettings: $personalSettings
      emailNotificationSettings: $emailNotificationSettings
    ) {
      ...UserBasicData
    }
  }
  ${User.fragments.UserBasicData}
`;
