import React from "react";

const ChatMessagesContainer = (props) => {
  const { id: chatBoxRef, type, onScroll, smoothScroll, loading } = props;

  return (
    <div
      className={`c-chat-box ${smoothScroll && "c-chat-box--smoothScroll"} ${
        type === "closed" && "c-chat-box--readOnly"
      } ${loading ? "c-chat-box--visible" : ""}`}
      onScroll={onScroll}
      ref={chatBoxRef}
    >
      {props.children}
    </div>
  );
};

export default ChatMessagesContainer;
