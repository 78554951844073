import moment from "moment-timezone";
import React from "react";
import {} from "phosphor-react";
import { useIntl } from "react-intl";

import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { Download } from "~/icons";

const ProcedureListItem = ({ item }) => {
  const { messages } = useIntl();

  return (
    <div className="procedure-item-container justify-between space-x-4">
      <div className="w-3/6 flex flex-col sm:flex-row sm:items-center sm:justify-between space-x-0 sm:space-x-2 space-y-2 sm:space-y-0">
        <BodyText
          size={BODY_TEXT_SIZES.X_SMALL}
          className="w-full sm:w-1/2 truncate"
        >
          {item.name}
        </BodyText>
        <BodyText
          size={BODY_TEXT_SIZES.X_SMALL}
          className="w-full sm:w-1/2 truncate"
        >
          <span className="text-secondary">
            {messages?.tickets?.createdText}:{" "}
          </span>
          {moment(item.createdAt).format("YYYY-MM-DD")}
        </BodyText>
      </div>
      <div className="flex justify-between items-center space-x-2 w-2/6">
        <div className="procedure-state finalized">
          <BodyText
            size={BODY_TEXT_SIZES.X_SMALL}
            className="truncate inline-block px-2xs py-3xs rounded-md bg-success-lightest"
            color="text-success"
          >
            {messages?.tickets?.procedureStates[item.state]}
          </BodyText>
        </div>
        <div className="flex justify-end h-fit">
          <div className="flex items-center justify-center u-padding-1 u-border-solid u-border-width-1 u-border-color-gray-v2-10 u-rounded-large">
            <Download
              onClick={() => window.open(item?.pdfUrl, "_blank")}
              className="u-cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcedureListItem;
