import React, { useEffect } from "react";
import ChatMessageItemText from "./_messageItem";
import ChatMessageItemFile from "./_messageItemFile";
import MessgaeSpinner from "./_messageSpinner";
import { useRecoilState } from "recoil";
import { chatAtom } from "~/atoms/_chat";

const ChatMessages = ({ intl, isFetching, data, groupedByDate }) => {
  const [chatOptions, setChatOptions] = useRecoilState(chatAtom);

  /**
   * Get messages from hook and set
   * transformed and grouped by date in recoil.
   */
  useEffect(() => {
    if (data?.length > 0) {
      setChatOptions({
        ...chatOptions,
        pubnub: {
          ...chatOptions?.pubnub,
          transformed: groupedByDate(data),
        },
      });
    }
  }, [data]);

  if (chatOptions?.pubnub?.transformed?.grouped?.length) {
    return chatOptions?.pubnub?.transformed?.grouped?.map((group) => {
      const dateNow = new Date().getDate();
      const dateMessage = new Date(group.date).getDate();

      return (
        <div key={group.date}>
          {isFetching && <MessgaeSpinner className="c-chat-topSpinner" />}
          <span className="c-chat-dateLabel text-sm">
            {dateNow === dateMessage
              ? "Today"
              : group.date.toLocaleDateString(intl.locale, {
                  weekday: "short",
                  month: "short",
                  day: "numeric",
                })}
          </span>
          {group?.messages?.map((item) => {
            if (item.userMetadata?.isFileMessage || item.meta?.isFileMessage)
              return null;

            if (item.message.file) {
              return (
                <ChatMessageItemFile
                  intl={intl}
                  key={item.timetoken}
                  message={item}
                />
              );
            } else {
              return (
                <ChatMessageItemText
                  intl={intl}
                  key={item.timetoken}
                  message={item}
                />
              );
            }
          })}
        </div>
      );
    });
  }

  return null;
};

export default ChatMessages;
