import React from "react";
import { Trash } from "phosphor-react";
import { useIntl } from "react-intl";
import { getPartThumbnail } from "#/src/utils";

const SinglePart = ({
  part,
  onDeleteClick,
  machine,
  disableDelete = false,
  extraClass,
  ...props
}) => {
  const { messages } = useIntl();

  return (
    <div className={`single-part ${extraClass}`} {...props}>
      <div className="single-part__image">
        <img src={getPartThumbnail(part, true)} />
      </div>
      <div className="single-part__info">
        <h4 className="single-part__info-title">{part.name}</h4>
        <div className="single-part__info-meta">
          <span>{messages?.parts?.labels.articleNumber}:</span>{" "}
          {part.articleNumber} &nbsp; &#8226;{" "}
          <span>{messages?.tickets?.quantity}:</span> {part.quantity}
        </div>
      </div>
      {!disableDelete && (
        <span className="single-part__action" onClick={onDeleteClick}>
          <Trash size={16} />
        </span>
      )}
    </div>
  );
};

export default SinglePart;
