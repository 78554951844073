import React, { useState, useEffect, useMemo, useRef, Fragment } from "react";
import { useIntl } from "react-intl";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { CaretDown, CaretUp, Check, WarningCircle } from "phosphor-react";
import useOnClickOutside from "use-onclickoutside";

const IconDropdown = ({
  options = [],
  onChange = () => {},
  className,
  search,
  defaultValue = null,
  placeholder,
  label,
  dropdownPos = "bottom",
  disable = false,
  itemRenderer = null,
  hideSelectedItem = false,
  dropdownTitle,
  defaultSelectedOption = null,
  setSearchQuery = () => {},
  loading,
  placeholderClassName = "",
  errorMessage = "",
}) => {
  const { messages } = useIntl();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(defaultSelectedOption);
  const [searchValue, setSearchValue] = useState("");
  const dropdownRef = useRef(null);

  const onClickOutside = () => {
    setIsDropdownOpen(false);
    if (searchValue) {
      setSearchValue("");
      setSearchQuery("");
    }
  };

  useOnClickOutside(dropdownRef, onClickOutside);

  // Filtered Dropdown Options
  const dropdownList = useMemo(() => {
    if (searchValue) {
      return options.filter((opt) =>
        opt.label.toLowerCase().includes(searchValue.toLowerCase()),
      );
    } else {
      return options;
    }
  }, [options, searchValue]);

  // On Dropdown Item Click
  const onSelectItem = (item) => {
    setSelectedItem(item);
    onChange(item);
    setIsDropdownOpen(false);
    setSearchValue("");
    setSearchQuery("");
  };

  // On Search
  const onSearch = (val) => {
    setSearchValue(val);
    setSearchQuery(val);
  };

  // Dropdown Toggler
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // If Default Value Exist, Set The Default Value as Selected Value
  useEffect(() => {
    let mounted = true;

    if (mounted && defaultValue) {
      setSelectedItem(defaultValue);
    }

    return () => {
      mounted = false;
    };
  }, [defaultValue]);

  return (
    <>
      <div className={`${className || ""} makula-dropdown-wrapper`}>
        {label && (
          <label className="makula-dropdown-field-label">
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-secondary"
              className="no-styles"
            >
              {label}
            </BodyText>
          </label>
        )}
        <div
          className={`${
            defaultValue ? "has-value" : ""
          } makula-dropdown-selected-value ${isDropdownOpen ? "focused" : ""}${
            disable ? "diabled" : ""
          }`}
          onClick={() => {
            if (!disable) {
              toggleDropdown();
            }
          }}
        >
          {selectedItem && !hideSelectedItem ? (
            <>
              {selectedItem.icon && (
                <span
                  className={`${selectedItem?.iconClass || ""} option-icon`}
                >
                  {selectedItem.icon}
                </span>
              )}
              <span className="option-text">{selectedItem.label}</span>
            </>
          ) : (
            <span className={"option-text " + placeholderClassName}>
              {placeholder}
            </span>
          )}
          <span className="toggle-icon">
            {isDropdownOpen ? <CaretUp size={16} /> : <CaretDown size={16} />}
          </span>
        </div>
        {isDropdownOpen && (
          <div
            className={`makula-dropdown-options-wrapper dropdown-position-${dropdownPos}`}
            ref={dropdownRef}
          >
            {dropdownTitle && (
              <div className="makula-dropdown-title u-width-100">
                <div className="body-text body-text--l2 u-text-color-gray-v2-60 u-capitalize">
                  {dropdownTitle}
                </div>
              </div>
            )}
            {search && (
              <div className="makula-search-in-dropdown">
                <input
                  type="search"
                  placeholder={messages?.tickets?.search}
                  onChange={(e) => onSearch(e?.target?.value)}
                />
              </div>
            )}
            <div className="makula-dropdown-options">
              {dropdownList.length > 0 ? (
                dropdownList.map((item, index) => {
                  if (itemRenderer) {
                    return (
                      <Fragment key={index}>
                        {itemRenderer(item, onSelectItem, index)}
                      </Fragment>
                    );
                  }
                  return (
                    <div
                      key={index}
                      className="single-option"
                      onClick={() => onSelectItem(item)}
                    >
                      {item.icon && (
                        <span
                          className={`${item?.iconClass || ""} option-icon`}
                        >
                          {item.icon}
                        </span>
                      )}
                      <span className="option-text">{item.label}</span>
                      {selectedItem &&
                        selectedItem?.itemId === item?.itemId && (
                          <span className="option-checked">
                            <Check size={16} />
                          </span>
                        )}
                    </div>
                  );
                })
              ) : (
                <>
                  {!loading && (
                    <div className="empty-dropdown u-text-center">
                      {messages?.common?.noAvailableOptions}
                    </div>
                  )}
                </>
              )}
              {loading && (
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  color="text-secondary"
                  className="p-lg no-styles"
                >
                  {messages?.common?.loading}
                </BodyText>
              )}
            </div>
          </div>
        )}
        {errorMessage && (
          <p className="input-v2-error-label u-padding-l-0">
            <WarningCircle className="input-v2-error-icon" size={16} />
            {errorMessage}
          </p>
        )}
      </div>
    </>
  );
};

export default React.memo(IconDropdown);
