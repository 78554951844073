import React, { useState, useEffect, Suspense } from "react";
import { injectIntl } from "react-intl";
import { useRecoilState, useRecoilValue } from "recoil";
import { imagePreviewAtom } from "~/atoms/_chat";
import { globalErrorAtom, globalAppVersionAtom } from "../atoms/_global";
import useAuth from "~/components/general/_use-auth";
import Main from "./_main";
import Routes from "~/routes/index";
import Navigation from "~/components/_navigation";
import ImagePreview from "~/components/_imagePreview";
import { Loading } from "~/components/general";
import LogRocket from "logrocket";
import { LoginAlert } from "../components/_alert";
import mixpanel from "mixpanel-browser";
import { FlashMessageWrapper } from "~/containers/_flashMessageWrapper";
import { isMixpanelEnabled } from "~/utils/_mixpanel";
import { isLogRocketEnabled } from "~/utils/_logRocket";
import { createPortal } from "react-dom";

let userIdentified;

const layout = (props) => {
  const { user = {}, loading } = useAuth() || {};
  const [routes, setRoutes] = useState([]);
  const { isErrorAlertOpen } = useRecoilValue(globalErrorAtom);
  const [imagePreviewOptions, setImagePreview] =
    useRecoilState(imagePreviewAtom);
  const flashMessage = useRecoilValue(globalAppVersionAtom);

  const closeImagePreview = () => {
    setImagePreview({
      ...imagePreviewOptions,
      isOpen: false,
    });
  };

  useEffect(() => {
    if (isLogRocketEnabled()) {
      if (user?._id && !userIdentified) {
        LogRocket.identify(user?._id, {
          name: user?.name,
          email: user?.email,
          role: user?.role,
        });
        userIdentified = true;
      }
    }
  }, [user]);

  useEffect(() => {
    if (!isMixpanelEnabled() || !user?._id) return;
    mixpanel.identify(user?._id);
    mixpanel.people.set({
      $name: user?.name,
      $email: user?.email,
      role: user?.role,
      oem: user?.oem?.slug,
      facilityName: user?.facility?.name,
    });
  }, [user]);

  useEffect(() => {
    if (!routes.length && Object.keys(user || {}).length)
      setRoutes(Routes(user));
  }, [user, loading]);

  if (!routes.length) return <p>...Loading</p>;

  // [NOTE]: this is for machine single view (no sidebar or navigation)
  /// ///////// BEGIN /////////////////
  const MACHINE_SINGLE_QRC_VIEW = false; // put this in app settings

  if (MACHINE_SINGLE_QRC_VIEW) {
    const { location } = props;
    if (location) {
      const path = "/machines/view/";
      if (location.pathname?.includes(path)) {
        const machinesViewRoute = routes.find((r) => r.path?.includes(path));
        if (machinesViewRoute) {
          const machineUuid =
            location.pathname.split("/")[
              location.pathname.split("/").length - 1
            ];
          return machinesViewRoute.main({
            ...props,
            machineUuid,
          });
        }
      }
    }
  }

  /// ///////// END ////////////////////

  return (
    <>
      {flashMessage?.showFlashMessage &&
        createPortal(<FlashMessageWrapper />, document.body)}
      <ImagePreview
        intl={props?.intl}
        closeModal={closeImagePreview}
        openedImage={imagePreviewOptions?.openedImage}
        isModalOpen={imagePreviewOptions?.isOpen}
      />
      <Navigation {...props} isOpen={true} routes={routes} />
      <Suspense fallback={<Loading />}>
        <Main {...props} routes={routes} DefaultRoute={routes[0]} />
      </Suspense>
      <LoginAlert />
      {isErrorAlertOpen && (
        <ErrorAlertBox
          isOpen={isErrorAlertOpen}
          overlay
          title={messages?.common?.clickToReload}
          onAccept={() => {
            window.location.reload();
          }}
        />
      )}
    </>
  );
};

export default injectIntl(layout);
