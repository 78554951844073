import { useMutation } from "@apollo/client";
import React from "react";
import { useIntl } from "react-intl";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { CURRENT_USER_QUERY, MACHINE_QRC_FACIITY_VIEW_MUTATION } from "~/api";
import { useAuth } from "~/components/general";

const MachineLogin = () => {
  const history = useHistory();
  const intl = useIntl();
  const { isLoggedIn, loading: userLoading } = useAuth(intl);
  const { machineUuid } = useParams();

  const [qrcMachineLogin, { loading: loadingMutation, error: errorMutation }] =
    useMutation(MACHINE_QRC_FACIITY_VIEW_MUTATION);

  React.useEffect(() => {
    const redirectionRoute = `/${intl?.locale}/app/machines/view/${machineUuid}`;

    if (userLoading === false && isLoggedIn) {
      history.replace(redirectionRoute);
      return;
    }

    if (
      machineUuid &&
      userLoading === false &&
      isLoggedIn === false &&
      !errorMutation &&
      !loadingMutation
    ) {
      qrcMachineLogin({
        variables: {
          qrcCredentials: {
            machineUuid,
          },
        },
        refetchQueries: [
          {
            query: CURRENT_USER_QUERY,
            variables: {},
          },
        ],
      }).then(() => history.replace(redirectionRoute));
    }
  }, [loadingMutation, userLoading, isLoggedIn]);

  if (errorMutation) {
    if (errorMutation.message === "QRC login not enabled") {
      return (
        <Redirect
          to={{
            pathname: "/qr-error",
          }}
        />
      );
    }
    if (typeof machineUuid !== "undefined") {
      return (
        <Redirect
          to={{
            pathname: "/error-page/404",
          }}
        />
      );
    }
  }

  return null;
};

export default MachineLogin;
