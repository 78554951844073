import React, { useRef } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import useOnClickOutside from "use-onclickoutside";
import { bool, element, func, string } from "prop-types";
import { useIntl } from "react-intl";

/**
 *
 * @param {} renderHeader React.ReactElement
 * @param {} renderBody React.ReactElement
 * @param {} renderFooter React.ReactElement
 * @param {} onClose callback function
 * @param {} isOpen false | true
 *
 */

const BaseModal = ({
  renderHeader,
  renderBody,
  renderFooter,
  onClose = () => {},
  isOpen = false,
  wrapperClass = "",
  width = "733px",
}) => {
  const ref = useRef(null);
  useOnClickOutside(ref, onClose);

  return isOpen
    ? ReactDOM.createPortal(
        <div className="mkl_modal-overlay">
          <div
            ref={ref}
            style={{ width }}
            className={"mkl_modal " + wrapperClass}
          >
            {renderHeader && (
              <div className="mkl_modal-header">{renderHeader}</div>
            )}
            {renderBody && <div className="mkl_modal-body">{renderBody}</div>}
            {renderFooter && (
              <div className="mkl_modal-footer">{renderFooter}</div>
            )}
          </div>
        </div>,
        document.body,
      )
    : null;
};

export default BaseModal;

export const GlobalModal = ({ contentClassname = "", ...props }) => {
  const { messages } = useIntl();
  return (
    <Modal
      preventScroll
      className={`global-modal ${props.className}`}
      isOpen={props.isOpen}
      shouldCloseOnOverlayClick={false}
      appElement={document.body}
      onRequestClose={props.handleClose}
      overlayClassName="modal-overlay"
      portalClassName={`fixed-modal ${props.isOpen ? "show" : ""}`}
    >
      <div className="modal__header">{props.header}</div>
      <div className={`modal__content ${contentClassname ?? ""}`}>
        {props.content}
      </div>
      <div className="modal__footer">
        {props.footer || (
          <>
            <button
              className={"btn-v2 ghost-btn-v2"}
              onClick={props.handleClose}
            >
              {props.cancelText || messages?.common.cancel}
            </button>
            {!props?.hideUpdateBtn && (
              <button
                className={`btn-v2 primary-btn-v2 ${
                  props.disable ? "c-button--disabled" : ""
                }`}
                disabled={props.disable}
                onClick={props.handleSubmit}
              >
                {props.submitText || messages?.common.update}
              </button>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

GlobalModal.propTypes = {
  header: element,
  content: element,
  handleSubmit: func,
  handleClose: func,
  isOpen: bool,
  disable: bool,
  submitText: string,
  cancelText: string,
};
