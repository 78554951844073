import React from "react";
import ContentLoader from "react-content-loader";

export const ProductionLineLoaderComponent = ({
  total = 2,
  width = 1154,
  height = 96,
}) => {
  const loaders = [];
  for (let i = 0; i < total; i++) {
    loaders.push(
      <ContentLoader
        className="production-line-content-loader"
        key={i}
        width={width}
        height={height}
      >
        <rect x="0" y="0" rx="8" ry="8" width="162" height="80" />
        <rect x="234" y="20" rx="0" ry="0" width="200" height="12" />
        <rect x="234" y="40" rx="0" ry="0" width="150" height="10" />
        <rect x="970" y="20" rx="0" ry="0" width="100" height="12" />
        <rect x="950" y="40" rx="0" ry="0" width="120" height="10" />
        <rect x="48" y="101" rx="0" ry="0" width="1102" height="1" />
      </ContentLoader>,
    );
  }

  return loaders;
};
