import React from "react";
import { useSlate } from "slate-react";
import { Button } from "./Button";
import { TEXT_ALIGN_TYPES } from "./Types";
import { isBlockActive, toggleBlock } from "./utils";

export const BlockButton = ({ format, icon, readOnly }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? "align" : "type",
      )}
      onMouseDown={(event) => {
        if (readOnly) return;
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <span>{icon}</span>
    </Button>
  );
};
