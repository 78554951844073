import { atom } from "recoil";

export const chatAtom = atom({
  key: "chatOptions",
  default: {
    pubnub: {},
  },
});

export const chatImagesAtom = atom({
  key: "chatImages",
  default: {
    images: [],
  },
});

export const imagePreviewAtom = atom({
  key: "imagePreviewOptions",
  default: {
    isOpen: false,
    openedImage: "",
  },
});

export const unreadMessagesAtom = atom({
  key: "unreadMessagesOptions",
  default: {
    channels: [],
    channelTimetokens: [],
    messageRead: false,
  },
});

export const unreadChannelMessagesAtom = atom({
  key: "unreadChannelMessages",
  default: {
    channels: {},
  },
});
