import { useMutation } from "@apollo/client";
import { CREATE_OWN_TICKET_MUTATION } from "~/api";

export const createTicket = () => {
  const [createOwnTicket, { error: { networkError } = {}, loading }] =
    useMutation(CREATE_OWN_TICKET_MUTATION);

  return {
    mutation: (data) =>
      createOwnTicket({
        variables: {
          input: {
            ...data,
          },
        },
        update(cache) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnCustomerTickets",
          });
          cache.gc();
        },
      }),
    loading,
    serverErrors: networkError,
  };
};
