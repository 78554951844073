import client from "../../apollo/_client";
import { GET_OWN_CUSTOMER_PRODUCTION_LINE_BY_ID_QUERY } from "~/api";

export const updateProductionLineCache = (payload) => {
  const productionLine = client.readQuery({
    query: GET_OWN_CUSTOMER_PRODUCTION_LINE_BY_ID_QUERY,
    variables: { id: payload._id },
  });

  if (!productionLine) return;

  const { getOwnCustomerProductionLineById: cachedProductionLine } =
    productionLine;
  if (cachedProductionLine) {
    const cloned = { ...cachedProductionLine };
    cloned.documentFolders = {
      ...(cloned.documentFolders ? cloned.documentFolders : {}),
      ...payload.documentFolders,
    };

    client.writeQuery({
      query: GET_OWN_CUSTOMER_PRODUCTION_LINE_BY_ID_QUERY,
      variables: { id: payload._id },
      data: {
        getOwnCustomerProductionLineById: cloned,
      },
    });
  }
};
