import React from "react";
import Modal from "react-modal";
import { useRecoilState } from "recoil";
import { useIntl } from "react-intl";

import { useAuth } from "./general";
import { useLocation } from "react-router-dom";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Button from "@shared/ui/Button";
import EmptySpace from "./_emptySpace";
import { globalLoginPopupForGMU } from "../atoms/_global";

export const LoginAlert = () => {
  const intl = useIntl();
  const { messages } = intl;
  const { logout } = useAuth(intl);
  const { pathname } = useLocation();
  const [gmuLoginCoil, setGMULoginCoil] = useRecoilState(
    globalLoginPopupForGMU,
  );

  const handleClose = (e) => {
    if (!pathname.includes("/machines/view")) return;
    setGMULoginCoil({ ...gmuLoginCoil, showPopup: false });
  };

  const handleLoginClick = (e) => {
    setGMULoginCoil({ ...gmuLoginCoil, showPopup: false });
    logout(true);
  };

  return (
    <Modal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={handleClose}
      className="login-alert"
      preventScroll
      isOpen={gmuLoginCoil.showPopup}
      overlayClassName="login-alert-modal-overlay"
      appElement={document.body}
      portalClassName={`fixed-modal ${gmuLoginCoil.showPopup ? "show" : ""}`}
    >
      <Headline size={HEADLINE_SIZES.SMALL}>{messages?.login?.login}</Headline>
      <EmptySpace height="20px" />
      <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
        {messages?.common?.restrictedAssetLogin}
      </BodyText>

      <EmptySpace height="40px" />

      <Button
        text={messages?.login?.login}
        fullWidth
        onClick={handleLoginClick}
      />
    </Modal>
  );
};
