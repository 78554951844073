import { getBoxAccessToken } from "~/services";

export default () => {
  const { user, loading } = getBoxAccessToken({ skip: false }) || {};
  const { foldersAccessToken: token } = user || {};
  return {
    token,
    loading,
  };
};
