import { CaretDown, Tabs } from "phosphor-react";
import React, { useEffect, useState } from "react";
import Label, { LABEL_SIZES } from "@shared/ui/Label";

export const Accordion = ({
  children,
  title,
  icon,
  tabToggleIcon,
  defaultOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  return (
    <div className="mk-accordion-wrapper">
      <div
        className="mk-accordion-header"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <span className="mk-accordion-icon">
          {icon || <Tabs size={16} weight="thin" />}
        </span>
        <Label size={LABEL_SIZES.SMALL}>{title || "Accordion Title"}</Label>
        <span className={`mk-accordion-toggler ${isOpen ? "v-flip" : ""}`}>
          {tabToggleIcon || <CaretDown size={16} weight="thin" />}
        </span>
      </div>
      {isOpen && <div className="mk-accordion-content">{children}</div>}
    </div>
  );
};
