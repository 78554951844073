import { useQuery } from "@apollo/client";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import mixpanel from "mixpanel-browser";
import React from "react";
import { injectIntl } from "react-intl";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_OEM_BY_QUERY } from "~/api";
import Toast from "~/components/_toast";
import { ForgotPasswordForm as Form } from "~/components/form";
import { FORGOT_PASSWORD, FORGOT_PASSWORD_FAILED } from "~/constants/global";
import { useBrandingProperties } from "~/hooks";
import { forgotPassword } from "~/services";
import { isMixpanelEnabled } from "~/utils/_mixpanel";

const ForgotPassword = ({ intl }) => {
  const history = useHistory();
  const { modifier } = useParams();
  const { mutation } = forgotPassword();

  const {
    data: { getOemBySlug: oem } = {},
    loading: loadingQuery,
    error,
  } = useQuery(GET_OEM_BY_QUERY, {
    variables: {
      slug: modifier,
    },
  });

  const { branding, isLighterBackground, buttonStyle } = useBrandingProperties(
    oem,
    intl,
  );

  const loading = loadingQuery;

  if (error) {
    if (typeof modifier !== "undefined") {
      return (
        <Redirect
          to={{
            pathname: "/error-page/404",
          }}
        />
      );
    }
  }

  return (
    <div className="auth">
      <div className="o-container">
        <div
          className={`authLoading ${loading || "authLoading--hidden"}`}
        ></div>

        <div className="auth-main auth-forgot-password-main">
          <div className="auth-box auth-forgot-password-box">
            <Headline size={HEADLINE_SIZES.SMALL} className="text-center mb-sm">
              {intl.messages?.common?.forgotPassword}
            </Headline>
            <p className="subtitle">{intl.messages?.forgotPassword?.title}</p>

            <Form
              color={branding?.backgroundColor}
              isLighterBackground={isLighterBackground}
              buttonStyle={buttonStyle}
              intl={intl}
              callback={(values) => {
                const { email: userEmail } = values;
                if (isMixpanelEnabled()) {
                  mixpanel.track(FORGOT_PASSWORD, {
                    oem: window.location.pathname
                      .split("/")
                      .slice(-1)
                      .join("")
                      .split("?")[0],
                    userEmail,
                  });
                }
                mutation(values)
                  .then(() => {
                    history.replace(
                      `/${intl?.locale}/forgot-password-success${
                        modifier ? "/" + modifier : ""
                      }?email=${encodeURIComponent(values?.email)}`,
                    );
                  })
                  .catch((e) => {
                    toast(
                      <Toast
                        type="error"
                        message={e?.message || intl.messages?.login?.wrongCreds}
                      />,
                      { closeButton: false },
                    );
                    if (isMixpanelEnabled()) {
                      mixpanel.track(FORGOT_PASSWORD_FAILED, {
                        oem: window.location.pathname
                          .split("/")
                          .slice(-1)
                          .join("")
                          .split("?")[0],
                        userEmail,
                      });
                    }
                  });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ForgotPassword);
