import React from "react";
import { useIntl } from "react-intl";
import { UserAvatar } from "./_avatar";
import { ASSIGNEES_AVATARS_MAX_LENGTH } from "../constants";
import ReactTooltip from "react-tooltip";

const TicketItemAssigneesList = ({
  assignees = [],
  showUserIconOnUnassigned = true,
  singleAssigneeClassName = "single-assignee-user-avatar-container",
  nameClassName = "",
}) => {
  const { messages } = useIntl();

  if (assignees.length <= 1)
    return (
      <div className={singleAssigneeClassName}>
        <UserAvatar
          showUserIcon={
            showUserIconOnUnassigned && (!assignees[0] || !assignees[0]?.name)
          }
          className={
            !assignees.length ? "not-assigned" : "has-name u-width-100"
          }
          name={assignees[0]?.name || messages?.common?.noAssignee}
          variant="extra-small"
          nameClassName={nameClassName}
        />
      </div>
    );

  const dataTip =
    assignees.length <= ASSIGNEES_AVATARS_MAX_LENGTH
      ? []
      : assignees
          .slice(ASSIGNEES_AVATARS_MAX_LENGTH, assignees.length)
          .map((assignee) => assignee?.name)
          .join(", ");

  return (
    <React.Fragment>
      {assignees.slice(0, ASSIGNEES_AVATARS_MAX_LENGTH).map((assignee) => (
        <React.Fragment key={assignee._id}>
          <UserAvatar
            name={assignee?.name || `${messages.common?.noName}`}
            variant="extra-small"
            showTooltip={true}
            className="assignee-circle"
            showName={false}
          />
        </React.Fragment>
      ))}
      {assignees.length > ASSIGNEES_AVATARS_MAX_LENGTH && (
        <>
          <div
            className="assignee-circle more-assignees-circle"
            data-for="avatar-tooltip"
            data-tip={dataTip}
          >
            {`+${assignees.length - ASSIGNEES_AVATARS_MAX_LENGTH}`}
          </div>
          <ReactTooltip
            id="ticket-item-assignees"
            effect="solid"
            place="top"
            className="react-tooltip-override"
          />
        </>
      )}
    </React.Fragment>
  );
};

export default TicketItemAssigneesList;
