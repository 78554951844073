import "~/tailwind.css";
import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/client";
import { apolloClient } from "./apollo";
import { RecoilRoot } from "recoil";
import LogRocket from "logrocket";
import "./index.css";
import LocaleHandler from "./LocaleHanlder";
import mixpanel from "mixpanel-browser";
import RecoilNexus from "recoil-nexus";
import { isMixpanelEnabled } from "./utils/_mixpanel";
import { isLogRocketEnabled } from "./utils/_logRocket";

if (isMixpanelEnabled()) {
  mixpanel.init(import.meta.env.VITE_APP_MIXPANEL_TOKEN, { debug: true });
}

if (isLogRocketEnabled()) {
  LogRocket.init(import.meta.env.VITE_APP_LOGOROCKET_ID, {
    release: import.meta.env.PACKAGE_VERSION,
  });
}

ReactDOM.render(
  <ApolloHooksProvider client={apolloClient}>
    <RecoilRoot>
      <RecoilNexus />
      <LocaleHandler></LocaleHandler>
    </RecoilRoot>
  </ApolloHooksProvider>,
  document.getElementById("root"),
);
