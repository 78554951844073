import React, { useMemo } from "react";

const ProgressBar = ({ completed, showLabel }) => {
  const fillerWidth = useMemo(() => {
    return { width: `${completed}%` };
  }, [completed]);

  return (
    <div className="progress-bar progress-bar-container">
      <div
        className={`progress-bar-filler ${
          completed < 74 ? "primary" : "secondary"
        }`}
        style={fillerWidth}
      >
        {showLabel && (
          <span className="progress-bar-label">{`${completed}%`}</span>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
