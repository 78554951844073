import { useEffect, useState } from "react";
import { useAuth } from "../components/general";
import usePubnubInstance from "./_pubnub-unread-messages";
import { useRecoilValue } from "recoil";
import { unreadChannelMessagesAtom } from "../atoms/_chat";
import { broadcaster } from "../utils/_appBroadcast";
import { WORK_ORDER_BROADCAST_TOPICS } from "../constants/workOrders";

const useNotification = () => {
  const { chat: chatInstance } = useAuth();
  const unreadChannelMessages = useRecoilValue(unreadChannelMessagesAtom);

  const [unreadLabels, setUnreadLabels] = useState({
    openTickets: 0,
  });

  const { getAllUnreadMessageCount } = usePubnubInstance(chatInstance)();

  useEffect(() => {
    const allChannels = Object.keys(unreadChannelMessages?.channels || {});

    if (allChannels.length) {
      const total = allChannels.reduce(
        (accumulator, channel) =>
          accumulator + unreadChannelMessages.channels[channel],
        0,
      );
      setUnreadLabels((prev) => ({ ...prev, openTickets: total }));
    }
  }, [unreadChannelMessages?.channels]);

  useEffect(() => {
    // getAllUnreadMessageCount fetches all channels with unread count
    // and set unreadChannelMessages atom
    getAllUnreadMessageCount();

    const unsubscribeWorkOrderList = broadcaster.subscribe(
      WORK_ORDER_BROADCAST_TOPICS.MEMBERSHIP_UPDATED,
      getAllUnreadMessageCount,
    );

    return () => {
      unsubscribeWorkOrderList(
        WORK_ORDER_BROADCAST_TOPICS.MEMBERSHIP_UPDATED,
        getAllUnreadMessageCount,
      );
    };
  }, []);

  return { unreadLabels };
};

export default useNotification;
