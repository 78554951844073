import React, { useState, useRef } from "react";
import { XCircle } from "phosphor-react";
import { useIntl } from "react-intl";

var accpetFiles = {
  1024: ".pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/msword",
  1025: "/*",
};

const formatFileSize = (size) => {
  switch (true) {
    case size < 1000:
      return size + " byte";
    case size < 1000000:
      return (size / 1000).toFixed(2) + " kb";
    case size >= 1000000:
      return (size / 1000000).toFixed(2) + " mb";
  }
};

const ChatActions = ({ sendMessage, sendFile }) => {
  const { messages } = useIntl();
  const [messageText, setMessageText] = useState("");
  const [file, setFile] = useState([]);
  const textareaRef = useRef();
  const fileUploadRef = useRef();
  const filenameContainerRef = useRef();

  const handleTextInputFocus = (e) => {
    if (!filenameContainerRef.current) return;

    if (
      !filenameContainerRef.current.classList.contains(
        "c-chat-fileName-container--bottom",
      )
    ) {
      filenameContainerRef.current.classList.add(
        "c-chat-fileName-container--bottom",
      );
    }
  };

  const handleTextInputBlur = (e) => {
    if (!filenameContainerRef.current) return;

    if (
      filenameContainerRef.current.classList.contains(
        "c-chat-fileName-container--bottom",
      ) &&
      !messageText?.trim()?.length
    ) {
      filenameContainerRef.current.classList.remove(
        "c-chat-fileName-container--bottom",
      );
    }
  };

  const handleMessageChange = (e) => {
    let content = e.target.value;
    setMessageText(content);
  };

  const handleFileInput = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files);
    }
  };

  const onTextSend = () => {
    let textMessage = messageText.trim().replace(/\n/gi, "<br />");
    let isFileMessage = false;
    if (file.length > 0) {
      sendFile(file[0]);
      if (!textMessage) {
        textMessage = file[0].name;
        isFileMessage = true;
      }
      setFile([]);
    }
    sendMessage(
      textMessage,
      function () {
        setMessageText("");
      },
      isFileMessage,
    );

    if (fileUploadRef.current) {
      fileUploadRef.current.value = null;
    }
  };

  return (
    <form className="form c-chat-form">
      <div className="form-group c-chat-actions">
        <label
          htmlFor="file-upload"
          className="c-chat-actionsAttach c-chat-actionsAttach--start"
        >
          <span className="c-chat-actionsAttachIcon"></span>
          <input
            ref={fileUploadRef}
            id="file-upload"
            name="file-upload"
            className="c-inputFile c-fileUpload-input"
            type="file"
            accept={
              // window.innerWidth > 1024 ? accpetFiles[1025] : accpetFiles[1024]
              accpetFiles[1025]
            }
            onChange={handleFileInput}
          />
        </label>
        {file.length > 0 && (
          <div
            ref={filenameContainerRef}
            className={`c-chat-fileName-container ${
              messageText?.length ? "c-chat-fileName-container--bottom" : ""
            }`}
          >
            <div className="c-chat-fileName" title={file[0].name}>
              {file[0].name}
            </div>
            <div className="c-chat-file-size">
              {formatFileSize(file[0].size)}
            </div>
            <div
              className="c-chat-file-clear"
              onClick={(e) => {
                e.stopPropagation();
                setFile([]);
                if (fileUploadRef.current) {
                  fileUploadRef.current.value = null;
                }
              }}
            >
              <XCircle />
            </div>
          </div>
        )}
        <textarea
          ref={textareaRef}
          rows="1"
          id="message"
          className={
            "w-full relative block font-manrope border border-solid border-gray-5 text-gray-700 text-sm transition-colors transition-shadow min-h-8 m-0 py-2 pr-20 pl-3 resize-none bg-primary rounded-lg focus:shadow-none focus:border-brand-lightest focus:outline-0"
          }
          value={messageText}
          onChange={handleMessageChange}
          placeholder={
            file.length > 0 ? "" : messages?.common?.chatBoxPlaceholder
          }
          onFocus={handleTextInputFocus}
          onBlur={handleTextInputBlur}
        ></textarea>
        <span className="c-chat-actionsSend" onClick={onTextSend}>
          <span className="c-chat-actionsSendIcon"></span>
        </span>
        <div className="c-chat-actionsAttach c-chat-actionsAttach--end">
          <span className="c-chat-actionsAttachCamera"></span>
          <input
            id="camera-upload"
            name="camera-upload"
            className="c-inputFile c-fileUpload-input"
            type="file"
            accept="image/*"
            capture
            onChange={handleFileInput}
          />
        </div>
      </div>
    </form>
  );
};

export default ChatActions;
