import React from "react";
import MachineListItem from "~/components/machine/MachineListItem";
import EmptySpace from "~/components/_emptySpace";
import { ITEMS_BEFORE_PAGE, ITEMS_PER_PAGE } from "~/constants/global";
import { Waypoint } from "react-waypoint";
import { MachineLoaderComponent } from "../components/skeletonLoaders/_machineLoders";
import Spinner from "../components/_spinner";

export default ({
  intl,
  header,
  machines,
  loading,
  totalCount,
  handleFetchMore,
  searchValue,
  setSearchValue,
}) => {
  const handleScrollToBottom = (event) => {
    if (
      machines.length >= ITEMS_PER_PAGE &&
      !loading &&
      event?.previousPosition !== "above" &&
      machines.length < totalCount
    ) {
      handleFetchMore({
        limit: ITEMS_PER_PAGE,
        skip: machines.length,
      });
    }
  };

  const renderList = () => {
    return machines?.map((item, index) => {
      return (
        <React.Fragment key={item._id}>
          <MachineListItem intl={intl} machine={item} inline />
          {index === machines.length - ITEMS_BEFORE_PAGE && (
            <Waypoint onEnter={handleScrollToBottom} />
          )}
        </React.Fragment>
      );
    });
  };

  if (loading && !machines.length && !searchValue) {
    return <Spinner />;
  }

  return (
    <>
      <div>
        {renderList()}
        {loading && <MachineLoaderComponent total={1} />}
        <EmptySpace height="30px" />
      </div>
    </>
  );
};
