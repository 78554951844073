import { LARGE_THUMBNAIL_DIMENSIONS, THUMBNAIL_DIMENSIONS } from "../constants";
export const getMachineThumbnail = (machine, isPreview = false) => {
  const thumbnailDimensions = isPreview
    ? LARGE_THUMBNAIL_DIMENSIONS
    : THUMBNAIL_DIMENSIONS;
  if (machine?.image) {
    return machine?.image + thumbnailDimensions;
  } else if (machine?.oem?.logo) {
    return machine?.oem?.logo + thumbnailDimensions;
  } else if (!machine?.oem?.logo) {
    return "/images/default/thumbnail.svg";
  }
  return null;
};
