import React from "react";
import { useIntl } from "react-intl";
import Button from "@shared/ui/Button";

const Actions = ({ ...props }) => {
  const { messages } = useIntl();

  return (
    <div className="mobile-element u-inline-flex u-width-100 mobile-view-actions">
      <Button
        text={messages?.header?.ticketListing?.action}
        fullWidth
        {...props}
      />
    </div>
  );
};

export default Actions;
