import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import mixpanel from "mixpanel-browser";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useRecoilState, useRecoilValue } from "recoil";

import CreateWorkOrder from "./Create";

import { TicketLoaderComponent } from "#/src/components/skeletonLoaders/_ticketLoaders";
import { WORK_ORDER_BROADCAST_TOPICS } from "#/src/constants/workOrders";
import useReorderWorkOrderList from "#/src/hooks/_useReorderWorkOrderList";
import { broadcaster } from "#/src/utils/_appBroadcast";
import { unreadChannelMessagesAtom } from "~/atoms/_chat.jsx";
import { OpenTicketsAtom } from "~/atoms/_openTicket";
import { TicketItemOpen } from "~/components/_ticketCard";
import { NoTicketsLarge } from "~/components/general/_no-tickets";
import Actions from "~/components/header/Actions";
import AppHeader from "~/components/header/AppHeader";
import Hamburger from "~/components/header/Hamburger";
import { ITEMS_PER_PAGE, VIEW_WORK_ORDERS } from "~/constants/global";
import { useListOwnCustomerTickets } from "~/services";
import { isMixpanelEnabled } from "~/utils/_mixpanel";

const WorkOrders = ({ intl, header }) => {
  const { messages } = useIntl();
  const unreadChannelMessages = useRecoilValue(unreadChannelMessagesAtom);
  const [openTicketCoil, setOpenTicketCoil] = useRecoilState(OpenTicketsAtom);
  const { handleReorderTicket } = useReorderWorkOrderList();

  const {
    tickets: data,
    loading,
    totalCount,
    handleFetchMore,
  } = useListOwnCustomerTickets({
    where: {
      myClosedWorkOrders: false,
    },
  });
  const handleScrollToBottom = (event) => {
    if (
      data.length >= ITEMS_PER_PAGE &&
      !loading &&
      event?.previousPosition !== "above" &&
      data.length < totalCount
    ) {
      handleFetchMore({
        limit: ITEMS_PER_PAGE,
        skip: data?.length,
      });
    }
  };

  const notificationChannels = unreadChannelMessages?.channels;
  const notificationKeys = Object.keys(notificationChannels);

  const renderTickets = () => {
    return (
      <>
        {!data.length && loading && <TicketLoaderComponent total={1} />}
        {!data.length && !loading && (
          <NoTicketsLarge
            intl={intl}
            title={intl?.messages?.tickets?.noTicket}
          />
        )}
        {data?.length > 0 && (
          <div className="page-list-item-wrapper has-bottom-actions">
            <TicketItemOpen
              intl={intl}
              data={data}
              onScrollBottom={handleScrollToBottom}
              loading={loading}
            />
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    const reorderList = (id, isNewTicket, isTicketDeleted, status) => {
      handleReorderTicket({
        allTickets: data,
        id,
        isNewTicket,
        isTicketDeleted,
        status,
      });
    };

    const unsubscribeReOrderList = broadcaster.subscribe(
      WORK_ORDER_BROADCAST_TOPICS.REORDER_WORK_ORDER_LIST,
      reorderList,
    );
    return () => {
      unsubscribeReOrderList(
        WORK_ORDER_BROADCAST_TOPICS.REORDER_WORK_ORDER_LIST,
        reorderList,
      );
    };
  }, [data]); // due to reordering inside cache we can't set tickets length as dependancy

  useEffect(() => {
    if (!isMixpanelEnabled()) return;
    mixpanel.track(VIEW_WORK_ORDERS);
  }, []);

  return (
    <>
      <AppHeader className="u-padding-y-3 u-items-center container-space-margin">
        <Headline size={HEADLINE_SIZES.SMALL}>
          {messages?.header?.ticketListing?.title} ({totalCount ?? 0})
        </Headline>
        <div className="u-flex u-items-center desktop-element">
          <CreateWorkOrder />
        </div>
        {/* Hamburger component is only for mobile view */}
        <Hamburger />
      </AppHeader>

      {renderTickets()}
      {/* Actions component is only for mobile view */}
      <Actions
        onClick={() =>
          setOpenTicketCoil({ ...openTicketCoil, isDrawerOpen: true })
        }
      />
    </>
  );
};

export default WorkOrders;
