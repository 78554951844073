import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import { TextAlignJustify } from "phosphor-react";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useParams, useHistory, Link } from "react-router-dom";

import ContentLoading from "../components/ContentLoading";
import { useAuth } from "../components/general";
import PartsTableWithPreview from "../components/parts/_parts-table-with-preview";
import { MAX_TEXT_DISPLAY_LENGTH } from "../constants/global";
import { getComponentById, getComponentPartsById } from "../services";
import { getComponentThumbnail } from "../utils";

import getEnums from "$/settings/enums";
import PAID_FEATURES from "$/settings/paid-features.json";
import EmptySpace from "~/components/_emptySpace";
import { Tab, Tabs } from "~/components/_tabs";
import { Accordion } from "~/components/accordion/_accordion";
import BoxUIElement from "~/components/boxUIElement";
import BreadCrumb from "~/components/BreadCrumb";
import TextEditor from "~/components/general/editor";
import { serialize } from "~/components/general/editor/utils";
import AppHeader from "~/components/header/AppHeader";
import Hamburger from "~/components/header/Hamburger";
import MobileSearch from "~/components/header/MobileSearch";
import { trimText } from "~/utils/_initials";

const paidFeaturesRef = getEnums(PAID_FEATURES, "reference");

const ComponentPage = ({ intl }) => {
  const { id } = useParams();
  const { messages } = useIntl();
  const history = useHistory();
  const [partSearchTerm, setPartSearchTerm] = React.useState("");
  const { user } = useAuth();

  const isComponentPaid = user?.oem?.paidFeatures.includes(
    paidFeaturesRef.components,
  );

  if (!isComponentPaid) history.replace(`/${intl?.locale}/app/`);

  const [component, loading] = getComponentById(id);
  const [inventoryParts, inventoryPartsLoading] = getComponentPartsById(id);

  const componentDescription = useMemo(() => {
    let description = "";
    if (serialize(component?.description)) {
      description = component?.description;
    } else {
      description = "";
    }
    return description;
  }, [component]);

  const breadCrumbs = React.useMemo(
    () => [
      {
        label: intl?.messages?.assets.breadCrumbs.assets,
        link: `/${intl?.locale}/app/assets`,
      },
      {
        label: intl?.messages?.assets.breadCrumbs.machines,
        link: `/${intl?.locale}/app/assets/machines`,
      },
      {
        label: component?.machine?.name ?? "",
        link: `/${intl?.locale}/app/assets/machines/${component?.machine?._id}`,
      },
      { label: trimText(component?.name, MAX_TEXT_DISPLAY_LENGTH) || "" },
    ],
    [component?.name],
  );

  const filteredComponentParts = useMemo(() => {
    return partSearchTerm
      ? inventoryParts?.filter((part) => {
          return (
            part?.part?.name
              ?.toLowerCase()
              .includes(partSearchTerm?.toLowerCase()) ||
            part?.part?.articleNumber
              ?.toLowerCase()
              .includes(partSearchTerm?.toLowerCase())
          );
        })
      : inventoryParts;
  }, [partSearchTerm, inventoryParts]);

  const renderComponentThumbnail = () => {
    let thumbnailToShow = getComponentThumbnail(component, true);

    return (
      <>
        {thumbnailToShow && (
          <figure className="machine-thumbnail">
            <img
              src={thumbnailToShow}
              alt={component?.name}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/images/default/thumbnail.svg";
              }}
            />
          </figure>
        )}
      </>
    );
  };

  return (
    <>
      <div className="single-machine-container">
        <div className="fw-page-header u-flex u-items-center u-width-100">
          <AppHeader className="bordered u-width-100">
            <BreadCrumb options={breadCrumbs} />
            {/* Hamburger component is only for mobile view */}
            <Hamburger />
          </AppHeader>
        </div>
        <div className="fw-page-content-wrapper u-flex u-flex-wrap u-width-100 u-padding-r-0">
          <div className="fw-page-main-content u-width-100">
            <div className="fw-machine-title">
              <Headline
                size={HEADLINE_SIZES.SMALL}
                className="max-md:line-clamp-2 max-md:text-ellipsis max-md:whitespace-normal"
              >
                {trimText(component?.name, MAX_TEXT_DISPLAY_LENGTH)}
              </Headline>
            </div>
            <div className="page-details-tab">
              <Tabs>
                <Tab
                  title={
                    intl?.messages?.machines?.machineDetails.titleTabs.details
                  }
                >
                  <div className="machine-details-container u-width-100">
                    <div className="machine-details-content u-width-100">
                      <div className="machine-meta-container u-items-center">
                        {renderComponentThumbnail()}
                        <div className="machine-meta">
                          <Label size={LABEL_SIZES.SMALL}>
                            {messages?.machines?.details}
                          </Label>
                          <EmptySpace height="14px"></EmptySpace>
                          <div className="u-flex u-width-100">
                            <div className="machine-meta-info u-margin-r-5">
                              <BodyText
                                size={BODY_TEXT_SIZES.X_SMALL}
                                color="text-secondary"
                                className="max-md:mr-2xs no-styles"
                              >
                                {intl?.messages?.components?.labels?.ID}
                              </BodyText>
                              <EmptySpace height="4px"></EmptySpace>
                              <BodyText
                                size={BODY_TEXT_SIZES.X_SMALL}
                                className="no-styles"
                              >
                                {trimText(
                                  component?.componentId,
                                  MAX_TEXT_DISPLAY_LENGTH,
                                )}
                              </BodyText>
                            </div>
                            <div className="machine-meta-info u-margin-r-5">
                              <BodyText
                                size={BODY_TEXT_SIZES.X_SMALL}
                                color="text-secondary"
                                className="max-md:mr-2xs no-styles"
                              >
                                {
                                  intl?.messages?.components?.labels
                                    ?.machineName
                                }
                              </BodyText>
                              <EmptySpace height="4px" />
                              <Link
                                to={`/${intl.locale}/app/assets/machines/${component?.machine?._id}`}
                              >
                                <BodyText
                                  size={BODY_TEXT_SIZES.X_SMALL}
                                  color="text-brand"
                                  className="cursor-pointer"
                                >
                                  {trimText(
                                    component?.machine?.name,
                                    MAX_TEXT_DISPLAY_LENGTH,
                                  )}
                                </BodyText>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Accordion
                        icon={<TextAlignJustify size={12} weight="bold" />}
                        title={messages?.machines?.description}
                      >
                        <TextEditor
                          content={componentDescription}
                          placeholder={" "}
                          readOnly={true}
                        />
                      </Accordion>
                    </div>
                  </div>
                </Tab>
                <Tab
                  title={
                    intl?.messages?.machines?.machineDetails.titleTabs
                      .documentation
                  }
                >
                  <>
                    {component &&
                      (!component.documentFolders?.externalId ? (
                        <ContentLoading />
                      ) : (
                        <BoxUIElement
                          key={component.documentFolders?.externalId}
                          token={user?.foldersAccessToken}
                          entityId={component.documentFolders?.externalId}
                          rootFolderId={component.documentFolders?.externalId}
                          entityName={component?.name}
                          canUpload={false}
                          canRename={false}
                          canDelete={false}
                          canCreateNewFolder={false}
                        />
                      ))}
                  </>
                </Tab>
                <Tab
                  title={
                    <span className="u-flex u-items-center u-width-100">
                      {intl?.messages?.machines?.machineDetails.titleTabs.parts}{" "}
                      &nbsp;
                      {inventoryPartsLoading ? (
                        <div className="loader" />
                      ) : (
                        `(${inventoryParts?.length || 0})`
                      )}
                    </span>
                  }
                >
                  <div className="spare-part-tab-content">
                    <MobileSearch
                      onChange={(value) => setPartSearchTerm(value)}
                      value={partSearchTerm}
                      className="production-line-machines-search"
                      isMobileElement={false}
                      placeholder={
                        intl?.messages?.parts?.machinePartTabSearchPlaceholder
                      }
                    />
                    <PartsTableWithPreview
                      hasInventoryParts={inventoryParts?.length > 0}
                      loading={loading}
                      notFoundMessage={
                        intl?.messages?.components?.noPartsMessage
                      }
                      intl={intl}
                      filteredParts={filteredComponentParts}
                    />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComponentPage;
