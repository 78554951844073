import { getAllRoles } from "@shared/utils/getAllRoles";
import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";

import { globalLoginPopupForGMU } from "~/atoms/_global";
import Toast from "~/components/_toast";
import { Authenticate, useAuth } from "~/components/general";
import { Layout } from "~/layout";
import ReloadPrompt from "~/ReloadPrompt";
import "react-calendar/dist/Calendar.css";

const ROLES = getAllRoles();

const App = ({ match, location }) => {
  const history = useHistory();
  const { messages } = useIntl();
  const { state } = location;
  const { chat, user, refetch } = useAuth();

  const [gmuModalCoil, setGMUModalCoil] = useRecoilState(
    globalLoginPopupForGMU,
  );

  React.useEffect(() => {
    let tokenInterval;

    if (chat && user) {
      const SECONDS = 60;
      const MILLISECONDS = 1000;
      const timeOffset = SECONDS * MILLISECONDS;
      const { ttl, timestamp } = chat?.parseToken(user?.chatToken) || {};
      const tokenTime = new Date(timestamp * 1000 + ttl * 60 * 1000).getTime();

      tokenInterval = setInterval(() => {
        const now = new Date().getTime() + timeOffset;
        if (now > tokenTime) {
          refetch();
        }
      }, 10 * MILLISECONDS);
    }

    return () => clearInterval(tokenInterval);
  }, [user]);

  React.useEffect(() => {
    if (
      user?.role === ROLES.GMU.VALUE &&
      !location.pathname.includes("/machines/view/")
    ) {
      setGMUModalCoil({ ...gmuModalCoil, showPopup: true });
    }
  }, [user?.role, location?.pathname]);

  React.useEffect(() => {
    if (state?.welcome) {
      toast(<Toast message={messages?.login?.welcome + "!"} />, {
        closeButton: false,
      });
      history.replace({ ...location, state: undefined });
    }
  }, []);

  return (
    <div className="main">
      <Authenticate>
        <Layout match={match} location={location} />
      </Authenticate>
      <ReloadPrompt />
    </div>
  );
};
App.propTypes = {
  match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default App;
