import { FolderLock, Wrench } from "phosphor-react";
import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import { useRecoilValue } from "recoil";

import { useAuth } from "./general";
import { TicketLoaderComponent } from "./skeletonLoaders/_ticketLoaders";
import TicketItemAssigneesList from "./ticketItemAssigneesList";
import WorkOrderTypeIcons from "./WorkOrderTypeIcons";
import ticketTypes from "../../../settings/enums/ticket/_types.json";
import { COLOR } from "../constants/colors";
import useWindowDimensions from "../hooks/_useWindowDimensions";
import { trimText } from "../utils/_initials";

import STATES from "$/settings/enums/procedure/states.json";
import PAID_FEATURES from "$/settings/paid-features.json";
import { unreadChannelMessagesAtom } from "~/atoms/_chat";
import EmptySpace from "~/components/_emptySpace";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { ITEMS_BEFORE_PAGE, MOBILE_UI_MAX_WIDTH } from "~/constants/global";
import { HourglassIcon, OpenFolderIcon, SidebarProceduresIcon } from "~/icons";
import { generateStatusBackgroundColor, getEnums } from "~/utils";

const paidFeaturesRef = getEnums(PAID_FEATURES, "reference");

export const TicketItemOpen = ({
  intl,
  data = [],
  path = "work-orders",
  onScrollBottom = () => {},
  loading = false,
}) => {
  const { user } = useAuth();

  return (
    <>
      {data?.map((item, index) => (
        <React.Fragment key={item._id}>
          <Link
            to={`/${intl?.locale}/app/${path}/${item._id}`}
            className="c-listItem c-listItem--hoverable ticket-list-item-container"
          >
            <TicketListComponent
              title={item?.title}
              ticketID={item?.ticketId}
              ticketType={item?.ticketType}
              facility={item?.facility?.name}
              machine={item?.machine?.name}
              status={item?.status}
              allStatuses={user?.oem?.statuses || []}
              date={item?.createdAt}
              totalComment={item?.comments?.length || 0}
              totalProcedures={
                item?.procedures?.filter(
                  (instance) => instance?.procedure?.state === STATES.FINALIZED,
                ).length || 0
              }
              ticketChannel={item?.ticketChatChannels?.[0]}
              isUnread={item?.unread}
              assignees={item?.assignees}
            />
          </Link>
          {index === data.length - ITEMS_BEFORE_PAGE && (
            <Waypoint onEnter={onScrollBottom} />
          )}
        </React.Fragment>
      ))}
      {loading && <TicketLoaderComponent total={1} />}
    </>
  );
};

export const TicketItemClosed = ({
  intl,
  data = [],
  path = "closed-work-orders",
  onScrollBottom = () => {},
  loading = false,
}) => {
  const { user } = useAuth();

  return (
    <>
      {data?.map((item, index) => (
        <React.Fragment key={item._id}>
          <Link
            to={`/${intl?.locale}/app/${path}/${item._id}`}
            className="c-listItem c-listItem--hoverable closed-ticket-list-item"
          >
            <TicketListComponent
              title={item?.title}
              ticketID={item?.ticketId}
              ticketType={item?.ticketType}
              facility={item?.facility?.name}
              machine={item?.machine?.name}
              status={item?.status}
              allStatuses={user?.oem?.statuses || []}
              date={item?.createdAt}
              totalComment={item?.comments?.length || 0}
              totalProcedures={
                item?.procedures?.filter(
                  (instance) => instance?.procedure?.state === STATES.FINALIZED,
                ).length || 0
              }
              assignees={item?.assignees}
            />
          </Link>
          {index === data.length - ITEMS_BEFORE_PAGE && (
            <Waypoint onEnter={onScrollBottom} />
          )}
        </React.Fragment>
      ))}
      {loading && <TicketLoaderComponent total={1} />}
    </>
  );
};

export const TicketListComponent = React.memo(
  ({
    status,
    allStatuses,
    title,
    facility,
    machine,
    totalComment,
    date,
    ticketID,
    ticketType,
    ticketChannel,
    totalProcedures,
    isUnread,
    assignees,
  }) => {
    const { user } = useAuth();
    const { messages } = useIntl();
    const { width: deviceWidth } = useWindowDimensions();
    const unreadChannelMessages = useRecoilValue(unreadChannelMessagesAtom);
    const unreadCount = unreadChannelMessages?.channels[ticketChannel];
    const [isHovered, setIsHovered] = useState(false);
    const [isMobileDevice, setIsMobileDevice] = useState(
      deviceWidth <= MOBILE_UI_MAX_WIDTH,
    );

    const statusItem = allStatuses?.find((item) => status === item._id);

    const isClosedWorkOrder = useMemo(
      () => status === allStatuses?.[allStatuses?.length - 1]?._id,
      [allStatuses],
    );
    const styles = useMemo(() => {
      return {
        backgroundColor: isHovered
          ? ticketType.color
          : generateStatusBackgroundColor(ticketType?.color),
      };
    }, [isHovered, ticketType.color]);

    const ticketTypeIcons = () => {
      if (ticketTypes[ticketType] === ticketTypes.SparePartOrder) {
        return <Wrench size={20} />;
      } else if (
        ticketTypes[ticketType] === ticketTypes.PreventiveMaintenance
      ) {
        return <HourglassIcon />;
      }

      return !isClosedWorkOrder ? (
        <OpenFolderIcon />
      ) : (
        <FolderLock className="icon" size={20} />
      );
    };

    React.useEffect(() => {
      setIsMobileDevice(deviceWidth <= MOBILE_UI_MAX_WIDTH);
    });

    const isProceduresPaid = user?.oem?.paidFeatures.includes(
      paidFeaturesRef.procedures,
    );

    const props = {
      icon: ticketTypeIcons(),
      status: statusItem,
      title,
      facility,
      machine,
      totalComment,
      date,
      ticketID,
      ticketType,
      ticketChannel,
      isUnread,
      unreadCount,
      assignees,
      totalProcedures,
      isProceduresPaid,
    };

    const handleMouseEnter = () => {
      setIsHovered(() => true);
    };
    const handleMouseLeave = () => {
      setIsHovered(() => false);
    };

    return (
      <>
        {!isMobileDevice ? (
          <div
            className={`ticket-item-wrapper${
              isClosedWorkOrder ? " closed-ticket-wrapper" : ""
            }`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="left-side-content">
              <span
                className="ticket-icon btn-v2 secondary-icon-btn-v2"
                style={styles}
              >
                <WorkOrderTypeIcons
                  icon={ticketType.icon}
                  strokeColor={isHovered ? COLOR.$white_v2 : ticketType.color}
                  size={20}
                />

                {parseInt(unreadCount) > 0 && <span className="badge"></span>}
              </span>
              <div className="title-wrapper">
                <Headline
                  size={HEADLINE_SIZES.X_SMALL}
                  className="mb-2xs overflow-hidden text-ellipsis whitespace-nowrap"
                >
                  {trimText(title)}
                </Headline>
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  color="text-secondary"
                  className="no-styles"
                >
                  #{ticketID}
                  {machine ? (
                    <>&nbsp;&nbsp;&#8226;&nbsp;&nbsp;{machine}</>
                  ) : null}
                </BodyText>
              </div>
            </div>

            <div className="right-side-content">
              {isProceduresPaid && (
                <div className="ticket-meta-data procedure-count u-margin-r-5">
                  <BodyText
                    size={BODY_TEXT_SIZES.X_SMALL}
                    color="text-secondary"
                    className="no-styles"
                  >
                    {totalProcedures}
                  </BodyText>
                  <SidebarProceduresIcon width={20} />
                </div>
              )}
              <div className="ticket-meta-data">
                <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="no-styles">
                  <span className="text-secondary">
                    {messages?.tickets?.creationDate}:
                  </span>{" "}
                  {date?.split("T")?.[0]}
                </BodyText>
              </div>
              <div className="ticket-meta-data-with-assignees u-margin-l-5">
                <div className="u-flex u-width-100">
                  <TicketItemAssigneesList
                    assignees={assignees}
                    nameClassName="max-w-[calc(100%-28px)] break-words"
                  />
                </div>
              </div>
              <div className="ticket-status-comment">
                <span
                  className="status-bar u-margin-b-0"
                  style={{
                    backgroundColor: isHovered
                      ? statusItem?.color
                      : generateStatusBackgroundColor(statusItem?.color),
                    color: isHovered ? COLOR.$white_v2 : statusItem?.color,
                  }}
                >
                  {statusItem.label}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <TicketCard {...props} />
        )}
      </>
    );
  },
);

export const TicketCard = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const iconStyles = {
    backgroundColor: isHovered
      ? props?.ticketType?.color
      : generateStatusBackgroundColor(props?.ticketType?.color),
  };

  return (
    <div
      className="ticket-card"
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <div className="flex items-center justify-between">
        <div className="flex flex-wrap items-center">
          <span className="icon" style={iconStyles}>
            <WorkOrderTypeIcons
              size={16}
              icon={props?.ticketType?.icon}
              strokeColor={
                isHovered ? COLOR.$white_v2 : props?.ticketType?.color
              }
            />
            {parseInt(props?.unreadCount) > 0 && <span className="badge" />}
          </span>
          <BodyText
            size={BODY_TEXT_SIZES.X_SMALL}
            color="text-secondary"
            className="no-styles"
          >
            #{props?.ticketID} &nbsp; &#8226; &nbsp;{" "}
            {props?.date?.split("T")?.[0]}
          </BodyText>
        </div>
        {props.isProceduresPaid && (
          <div className="flex space-x-2 items-center ml-2">
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-secondary"
              className="no-styles"
            >
              {props.totalProcedures}
            </BodyText>
            <SidebarProceduresIcon width={20} />
          </div>
        )}
      </div>
      <div className="card-middle">
        <Label size={LABEL_SIZES.MEDIUM}>{trimText(props?.title)}</Label>
        <EmptySpace height="6px" />
        <BodyText
          size={BODY_TEXT_SIZES.X_SMALL}
          color="text-secondary"
          className="no-styles"
        >
          {props?.machine} &nbsp; &#8226; &nbsp; {props?.facility}
        </BodyText>
      </div>
      <div className="card-bottom u-flex u-justify-between">
        {!props.hideAssignees && (
          <div className="u-flex u-width-100">
            <TicketItemAssigneesList
              assignees={props.assignees}
              nameClassName="max-w-[calc(100%-28px)] break-words"
            />
          </div>
        )}
        <span
          className="status-bar u-margin-y-auto u-whitespace-nowrap"
          style={{
            color: isHovered ? COLOR.$white_v2 : props?.status?.color,
            backgroundColor: isHovered
              ? props?.status?.color
              : generateStatusBackgroundColor(props?.status?.color),
          }}
        >
          {props?.status?.label}
        </span>
      </div>
    </div>
  );
};
