import { useRef } from "react";

import { useMutation, gql } from "@apollo/client";
import { toast } from "react-toastify";

// config
const providers = ["s3"];

// mutation
const _safeS3SignMutation = gql`
  mutation ($filename: String!, $filetype: String!, $type: String) {
    _safeSignS3(filename: $filename, filetype: $filetype, type: $type) {
      url
      signedRequest
    }
  }
`;

export default (args) => {
  const { toastRef } = args || {};
  const toastId = toastRef || useRef(0);

  const startToast = () => {
    return !toastRef
      ? (toastId.current = toast("Upload in progress...", {
          type: toast.TYPE.INFO,
          autoClose: false,
          progress: 0,
          hideProgressBar: false,
        }))
      : toast.update(toastId.current, {
          type: toast.TYPE.INFO,
          progress: 0,
          hideProgressBar: false,
          autoClose: false,
          render: "Upload in progress...",
        });
  };

  const updateToast = (type, message, options = {}) => {
    toast.update(toastId.current, {
      type: type || toast.TYPE.SUCCESS,
      render: message,
      ...options,
    });
  };

  const endToast = () => {
    toast.done(toastId.current);
    return (toastId.current = toast.dismiss(toastId.current));
  };

  const [safeS3Sign] = useMutation(_safeS3SignMutation);
  const fileUploader = async ({ files = [], type = "s3", folder }) => {
    if (!providers.includes(type))
      return {
        success: false,
        hasError: true,
        errors: [
          {
            message: `Provider [${type}] is not supported. Only supported providers are ${providers.join(
              ", ",
            )}`,
          },
        ],
        urls: [],
      };
    let allFiles = [];
    let errors = [];
    if (Array.isArray(files)) {
      const totalFiles = files.filter(
        (f) => !!f && typeof f !== "string",
      ).length;

      if (totalFiles > 0) {
        startToast();
      }

      allFiles = await files.reduce(async (arr, file, index) => {
        await arr;

        const currentFile = index + 1;
        updateToast(
          toast.TYPE.INFO,
          `Uploading ${currentFile} of ${totalFiles}`,
          {
            progress: index / totalFiles,
          },
        );
        let attachmentUrl = file || null;
        if (attachmentUrl) {
          if (typeof attachmentUrl === "string")
            return [...(await arr), attachmentUrl];
          const { type, file: dataFile = file } = file;
          // const { size } = dataFile
          // if (size > maxSize) {
          //   toast.error(translations.messagesNewMessageErrorFileBig)
          //   return null
          // }
          try {
            const response = await safeS3Sign({
              variables: {
                filename: dataFile.name,
                filetype: dataFile.type || "text/html",
                type: folder,
              },
            });
            const { signedRequest, url: bucketUrl } = response.data._safeSignS3;
            const options = {
              headers: {
                "Content-Type": type,
              },
            };
            await new Promise(async (resolve, reject) => {
              await fetch(signedRequest, {
                method: "PUT",
                headers: options.headers,
                body: dataFile,
              })
                .then((data) => {
                  attachmentUrl = bucketUrl;
                  resolve(data);
                })
                .catch((e) => {
                  attachmentUrl = null;
                  errors.push(e);
                  reject(e);
                });
            });
          } catch (e) {
            errors.push(e);
            return [...(await arr)];
          }
          return [...(await arr), attachmentUrl];
        }
      }, []);
      if (!errors.length) {
        updateToast(toast.TYPE.SUCCESS, "Upload complete!", {
          hideProgressBar: true,
          autoClose: 1000,
        });
      } else updateToast(toast.TYPE.ERROR, "Upload Error!", { progress: 1 });
      setTimeout(() => endToast(), 1000); // NOTE: this is an hack. We should figure out why the referenced toast won't close itself
      return {
        toastId,
        success: !errors.length,
        hasError: errors.length,
        errors,
        urls: allFiles,
      };
    }
  };
  return fileUploader;
};
