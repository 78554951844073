import React from "react";
import { useIntl } from "react-intl";
import { MakulaLogoWhiteSVG } from "~/icons";

export const InfoPageFull = ({ title, message, showPoweredBy }) => {
  const { messages } = useIntl();
  return (
    <div className="auth">
      <div className="auth-hero-section-wrapper u-width-100">
        <div className="o-container auth-hero-container">
          <div className="auth-hero-content">
            {title && <h1 className="hero-title">{title || ""}</h1>}
            {message && <p className="hero-subtitle">{message || ""}</p>}
          </div>
        </div>

        {showPoweredBy && (
          <div className="auth-footer">
            <span className="auth-footerCopy" style={{ color: "#ffffff" }}>
              {messages?.common?.poweredBy}
            </span>
            {/* {svgLogo(oem?.textColor || enumLoginColors.text_color)} */}
            {<MakulaLogoWhiteSVG />}
          </div>
        )}
      </div>
    </div>
  );
};
