import { useMutation } from "@apollo/client";

import { FORGOT_PASSWORD_MUTATION } from "~/api";

export const forgotPassword = () => {
  const [forgotPassword, { error = {}, loading }] = useMutation(
    FORGOT_PASSWORD_MUTATION,
  );

  return {
    mutation: (values) =>
      new Promise((resolve, reject) => {
        return forgotPassword({
          variables: {
            ...values,
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      }),
    loading,
    error,
  };
};
