import { LARGE_THUMBNAIL_DIMENSIONS, THUMBNAIL_DIMENSIONS } from "~/constants";
export const getComponentThumbnail = (component, isPreview = false) => {
  const thumbnailDimensions = isPreview
    ? LARGE_THUMBNAIL_DIMENSIONS
    : THUMBNAIL_DIMENSIONS;
  if (component?.image) {
    return component?.image + thumbnailDimensions;
  } else if (component?.oem?.logo) {
    return component?.oem.logo + thumbnailDimensions;
  } else if (!component?.oem?.logo) {
    return "/images/default/thumbnail.svg";
  }
  return null;
};
