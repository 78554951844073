import React from "react";
import { oneOf, string } from "prop-types";

const AppHeader = ({ contentPosition, className, children, ...props }) => {
  return (
    <div
      className={`app-header-container u-flex u-justify-${contentPosition} ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

AppHeader.propTypes = {
  contentPosition: oneOf(["start", "center", "end", "between"]),
  className: string,
};

AppHeader.defaultProps = {
  contentPosition: "between",
  className: "",
};

export default AppHeader;
