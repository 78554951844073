import React from "react";
import PropTypes from "prop-types";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Check if the error is related to chunk loading
    const isChunkLoadError =
      error.message.includes(
        "'text/html' is not a valid JavaScript MIME type",
      ) ||
      error.message.includes("error loading dynamically imported module") ||
      error.message.includes("Failed to fetch dynamically imported module") ||
      error.message.includes("Importing a module script failed") ||
      error?.name === "ChunkLoadError" ||
      error?.message.includes("Loading chunk");

    if (isChunkLoadError) {
      window.location.reload(true);
    } else {
      this.setState({ hasError: true, error });
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="error-container">
          <h3>Oops, something went wrong!</h3>
          <p>
            Try to refresh this page or load{" "}
            <a href="/" onClick={() => window.location("/")}>
              Home
            </a>
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};
