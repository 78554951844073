import { atom } from "recoil";

export const globalLoginPopupForGMU = atom({
  key: "globalLoginPopupForGMU",
  default: {
    showPopup: false,
  },
});

export const globalAppVersionAtom = atom({
  key: "globalAppVersionAtom",
  default: {
    minimumAppVersionSupported: "",
    showFlashMessage: false,
  },
});

export const globalErrorAtom = atom({
  key: "globalErrorAtom",
  default: {
    isErrorAlertOpen: false,
  },
});
