import * as React from "react";
const SvgCommunityIcon = (props) => /* @__PURE__ */ React.createElement("svg", {
  width: 16,
  height: 16,
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M13.8609 6.34814C14.3876 6.87483 14.3876 7.72875 13.8609 8.25544C13.3342 8.78213 12.4803 8.78213 11.9536 8.25544C11.4269 7.72875 11.4269 6.87483 11.9536 6.34814C12.4803 5.82145 13.3342 5.82145 13.8609 6.34814",
  stroke: "#172B4D",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M9.46697 3.94418C10.278 4.75516 10.278 6.07004 9.46697 6.88103C8.65598 7.69202 7.34111 7.69202 6.53012 6.88103C5.71913 6.07004 5.71913 4.75517 6.53012 3.94418C7.3411 3.13319 8.65598 3.13319 9.46697 3.94418",
  stroke: "#172B4D",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M4.0445 6.34814C4.57119 6.87483 4.57119 7.72875 4.0445 8.25544C3.51782 8.78213 2.66389 8.78213 2.1372 8.25544C1.61052 7.72875 1.61052 6.87483 2.1372 6.34814C2.66389 5.82145 3.51782 5.82145 4.0445 6.34814",
  stroke: "#172B4D",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M15.3335 12.6708V11.9401C15.3335 11.0194 14.5875 10.2734 13.6668 10.2734H13.1328",
  stroke: "#172B4D",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M0.666016 12.6708V11.9401C0.666016 11.0194 1.41202 10.2734 2.33268 10.2734H2.86668",
  stroke: "#172B4D",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M11.5588 12.6663V11.599C11.5588 10.3103 10.5141 9.26562 9.22545 9.26562H6.77279C5.48412 9.26562 4.43945 10.3103 4.43945 11.599V12.6663",
  stroke: "#172B4D",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgCommunityIcon;
