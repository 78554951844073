import React from "react";

/**
 * Image preview.
 * Located in layout.
 *
 * @param {Object}
 * @return {Function}
 */
const ImagePreview = ({ intl, isModalOpen, closeModal, openedImage }) => {
  const common = intl.messages.common;

  if (!openedImage) {
    return null;
  }

  return (
    <div className={`c-modal ${isModalOpen && "c-modal--open"}`}>
      <div className="c-modal-header">
        <button className="c-modal-headerClose" onClick={closeModal}>
          <span>{common.close}</span>
        </button>
      </div>
      {isModalOpen && openedImage && (
        <div className="c-modal-content c-modal-content--centered">
          <img key={openedImage} src={openedImage} />;
        </div>
      )}
    </div>
  );
};

export default ImagePreview;
