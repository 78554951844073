import { atom } from "recoil";

export const OpenTicketsAtom = atom({
  key: "OpenTicketsAtom",
  default: {
    totalTickets: 0,
    totalUnreadTickets: 0,
    isDrawerOpen: false,
    isPartsRequest: false,
    defaultSelectedMachine: null,
    description: "",
  },
});
