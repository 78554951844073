import React from "react";
import { arrayOf, shape, string } from "prop-types";
import { Link } from "react-router-dom";

import { trimText } from "../utils";

const BreadCrumb = ({ options }) => {
  const count = options.length;
  const modOptions = React.useMemo(() => {
    if (count > 3) {
      return [
        options[0],
        { label: "..." },
        options[count - 2],
        options[count - 1],
      ];
    }
    return options;
  }, [options, count]);

  return (
    <div className="bread-crumb-container u-inline-flex u-items-center">
      {modOptions.map((option, index) => (
        <React.Fragment key={`bread-crumb-${index}`}>
          {Object.hasOwnProperty.call(option, "link") ? (
            <span className="bread-crumb-link-label">
              <Link to={option.link} className="bread-crumb-link-label">
                {option.label}
              </Link>
            </span>
          ) : (
            <span className="bread-crumb-plain-label" title={option.label}>
              {trimText(option.label)}
            </span>
          )}
          {modOptions.length - 1 !== index && (
            <span className="bread-crumb-seperator">&nbsp;/&nbsp;</span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

const OptionsType = shape({
  label: string.isRequired,
  link: string,
});

BreadCrumb.propTypes = {
  options: arrayOf(OptionsType).isRequired,
};

BreadCrumb.defaultProops = {
  options: [],
};

export default BreadCrumb;
