import COLOR_PALETTE from "$/settings/color-palette.json";

export const hexToHSL = (H) => {
  // Convert hex to RGB first
  let r = 0;
  let g = 0;
  let b = 0;
  if (H.length === 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length === 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h, s, l };
};

export const hexToHsb = (hexCode) => {
  // Remove the hash character from the hex code if present
  const hex = hexCode.replace("#", "");

  // Convert hex to RGB
  const r = parseInt(hex.substring(0, 2), 16) / 255;
  const g = parseInt(hex.substring(2, 4), 16) / 255;
  const b = parseInt(hex.substring(4, 6), 16) / 255;

  // Find the maximum and minimum values among R, G, B
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  // Calculate the brightness
  const brightness = max;

  // Calculate the saturation
  const saturation = max === 0 ? 0 : (max - min) / max;

  // Calculate the hue
  let hue;
  if (max === min) {
    hue = 0;
  } else if (max === r) {
    hue = (60 * ((g - b) / (max - min)) + 360) % 360;
  } else if (max === g) {
    hue = 60 * ((b - r) / (max - min)) + 120;
  } else {
    hue = 60 * ((r - g) / (max - min)) + 240;
  }

  return { h: hue, s: saturation * 100, b: brightness * 100 };
};

export const mapColorsFromPalette = (
  keyName = "primary",
  valueName = "light",
) => {
  return Object.keys(COLOR_PALETTE).reduce((accumulator, current) => {
    accumulator[COLOR_PALETTE[current][keyName]] =
      COLOR_PALETTE[current][valueName];
    return accumulator;
  }, {});
};

const PRIMARY_SECONDARY_COLOR_MAP = mapColorsFromPalette();
export const generateStatusBackgroundColor = (color) => {
  return PRIMARY_SECONDARY_COLOR_MAP[color?.toLowerCase() || ""];
};
