import { FolderOpen, Repeat } from "phosphor-react";
import React, { useRef } from "react";
import { useIntl } from "react-intl";
import useOnClickOutside from "use-onclickoutside";

import { HorizontalBarsIcon, CalendarBlankIcon } from "~/icons";
import { formatDate } from "~/utils";

const EventPreview = ({
  title,
  repeatIn,
  repeatInNumber,
  eventDate,
  description,
  ticketCreationIn,
  ticketCreationNumber,
  nextCreationDate,
  onOutsideClick = () => {},
}) => {
  const ref = useRef(null);
  const {
    messages: { preventiveMaintenance: PM },
  } = useIntl();

  useOnClickOutside(ref, onOutsideClick);

  return (
    <section className="preview-event" ref={ref}>
      <div className="preview-event-body">
        <div className="preview-event-title">
          <h3>{title}</h3>
        </div>
        <div className="meta-data">
          <p className="date-time-progress">
            <span className="peb-icon">
              <Repeat size={16} />
            </span>
            {PM?.maintenanceOn}&nbsp; • &nbsp;
            <span className="date">{formatDate(eventDate)}</span>
          </p>
        </div>

        <div className="meta-data">
          <p className="repeat">
            <span className="peb-icon">
              <CalendarBlankIcon />
            </span>
            {PM?.repeatEvery}&nbsp; • &nbsp;
            <span className="frequency-time">
              {repeatInNumber !== 0 ? (
                <>
                  {repeatInNumber} {PM?.frequency[repeatIn.toLowerCase()]}
                </>
              ) : (
                <>{PM?.form.frequencyNoRepeat}</>
              )}
            </span>
          </p>
        </div>

        {ticketCreationIn && (
          <div className="meta-data">
            <p className="repeat">
              <span className="peb-icon">
                <FolderOpen size={16} />
              </span>
              {PM?.previewEvent.ticketCreationText} &nbsp; • &nbsp;
              <span className="event-creation-time">
                {ticketCreationNumber +
                  " " +
                  PM?.frequency[ticketCreationIn.toLowerCase()] +
                  " "}
                {PM?.previewEvent.ticketCreationEnd}
              </span>
            </p>

            <p className="sub-meta">
              {new Date().getTime() > new Date(nextCreationDate)?.getTime?.()
                ? PM?.previewEvent.ticketBeforeCreationDate
                : PM?.previewEvent.ticketCreationDate}{" "}
              {formatDate(nextCreationDate)}
            </p>
          </div>
        )}

        {!!description && (
          <div className="meta-data">
            <p className="pe-description">
              <span className="peb-icon">
                <HorizontalBarsIcon />
              </span>
              {description}
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default EventPreview;
