import React, { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { chatAtom, chatImagesAtom, unreadMessagesAtom } from "~/atoms/_chat";
import { usePubNub, usePubnubInstance } from "~/hooks";
import { unreadChannelMessagesAtom } from "~/atoms/_chat.jsx";
import ChatActions from "~/components/chat/_actions";
import { useAuth } from "~/components/general";
import ChatIndicator from "../components/chat/_indicator";
import ChatMessages from "../components/chat/_messages";
import ChatMessagesContainer from "../components/chat/_messagesContainer";
import MessgaeSpinner from "../components/chat/_messageSpinner";
import Label, { LABEL_SIZES } from "@shared/ui/Label";

const Chat = ({ ticket, type, intl, backUrl }) => {
  const chatOptions = useRecoilValue(chatAtom);
  const chatImages = useRecoilValue(chatImagesAtom);
  const [unreadMessagesOptions, setUnreadMessagesOptions] =
    useRecoilState(unreadMessagesAtom);
  const markedReadOnRender = useRef(false);

  const { chat: chatInstance, user } = useAuth();

  const firstRenderRef = useRef();
  const chatBoxRef = useRef(null);

  const [showChatSpinner, setShowChatSpinner] = useState(true);

  const [scrollAnimation, setScrollAnimation] = useState(false);
  const [unreadIndicator, setUnreadIndicator] = useState(false);
  const [unreadChannelMessages, setUnreadChannelMessages] = useRecoilState(
    unreadChannelMessagesAtom,
  );

  const {
    groupedByDate,
    messages,
    messagesCount,
    loadMore,
    onLoadMore,
    sendMessage,
    sendFile,
    isFetching,
  } = usePubNub(chatInstance, ticket?.ticketChatChannels, user, ticket?._id);

  const pubnubInstance = usePubnubInstance(chatInstance)();

  const markMessageAsRead = () => {
    const notiChans = { ...unreadChannelMessages.channels };
    notiChans[ticket?.ticketChatChannels[0]] = 0;
    setUnreadChannelMessages({
      channels: notiChans,
    });
    pubnubInstance.updateSingleMembership({
      id: ticket?.ticketChatChannels?.[0],
      custom: {
        lastReadTimetoken: parseInt(
          messages[messages?.length - 1]?.timetoken ??
            new Date().getTime() * 10000,
        ),
      },
    });
  };

  let onEdge = () => {
    if (!isFetching) {
      onLoadMore(loadMore + 1);
    }
  };

  const handleScroll = (e) => {
    if (
      e.type === "scroll" &&
      (isFetching || messages.length > messagesCount)
    ) {
      // prevent multiple calls
      return null;
    }
    let element = e.target;
    if (element.scrollTop === 0) {
      if (messagesCount !== messages?.length) {
        onEdge();
        chatBoxRef.current.scrollTop = 30;
      }
    }

    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      if (unreadIndicator) {
        setUnreadMessagesOptions({
          ...unreadMessagesOptions,
          channels: [ticket?.ticketChatChannels?.[0]],
          channelTimetokens: [new Date().getTime() * 10000],
          messageRead: true,
        });
        setUnreadIndicator(false);
      }
    }
  };

  /**
   * On first render scroll to last message and
   * set scroll behaiver to be smooth.
   */
  useEffect(() => {
    const loadingImages = messages?.filter?.(
      (m) =>
        m?.messageType === 4 &&
        m?.message?.type?.includes("image") &&
        !m?.message?.type?.includes("heic"),
    );
    const loadingImagesLength = loadingImages?.length ?? 0;

    if (loadingImagesLength && !firstRenderRef.current) {
      if (!showChatSpinner) {
        setShowChatSpinner(true);
      }
    }

    if (messages?.length > 0 && !firstRenderRef.current) {
      if (Object.keys(chatOptions?.pubnub?.newMessage).length === 0) {
        const chatBoxImages = chatBoxRef?.current.getElementsByTagName("img");
        if (
          loadingImagesLength === chatBoxImages.length &&
          loadingImagesLength > 0
        ) {
          Promise.all(
            Array.from(chatBoxImages).map((img) => {
              return new Promise((resolve) => {
                resolve(true);
              });
            }),
          ).then((results) => {
            if (results.length > 0 && results.length === chatBoxImages.length) {
              chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
              firstRenderRef.current = true;
              setScrollAnimation(true);
              setShowChatSpinner(false);
            }
          });
        } else {
          if (!loadingImagesLength) {
            setTimeout(
              () =>
                (chatBoxRef.current.scrollTop =
                  chatBoxRef.current.scrollHeight),
              setScrollAnimation(true),
              setShowChatSpinner(false),
              0,
            );
            firstRenderRef.current = true;
          }
        }
      }
    }
  }, [messages, document.images, chatImages.images]);

  useEffect(() => {
    if (messagesCount === 0) {
      setShowChatSpinner(false);
    }
  }, [messagesCount]);

  /**
   * Scroll to bottom when message is send.
   * If message received and if user not in viewport highlight indicator arrow
   * else turn off.
   */
  useEffect(() => {
    if (messages?.length > 0) {
      if (chatOptions?.pubnub?.newMessage?.externalSender === false) {
        setTimeout(() => {
          setUnreadMessagesOptions({
            ...unreadMessagesOptions,
            channels: [ticket?.ticketChatChannels?.[0]],
            channelTimetokens: [new Date().getTime() * 10000],
            messageRead: true,
          });
          chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }, 10);
      }

      if (chatOptions?.pubnub?.newMessage?.externalSender === true) {
        setTimeout(() => {
          chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }, 10);
        markMessageAsRead();
        if (
          chatBoxRef.current.scrollTop + chatBoxRef.current.clientHeight >=
          chatBoxRef.current.scrollHeight - 70
        ) {
          if (unreadIndicator) {
            setUnreadIndicator(false);
          }
        } else {
          if (!unreadIndicator) {
            setUnreadIndicator(true);
          }
        }
      }
    }
  }, [chatOptions?.pubnub?.newMessage?.time]);

  useEffect(() => {
    if (
      ticket?.ticketChatChannels?.length &&
      messages.length &&
      !markedReadOnRender.current
    ) {
      markMessageAsRead();
      markedReadOnRender.current = true;
    }
  }, [ticket, messages]);

  return (
    <>
      <div className={"c-chat"}>
        <div className="c-chat-inner">
          <div className="c-chat-header">
            <Label size={LABEL_SIZES.MEDIUM} className="truncate">
              #{ticket?.ticketId}
            </Label>
          </div>
          {showChatSpinner && <MessgaeSpinner className="c-chat-spinner" />}
          <ChatMessagesContainer
            onScroll={handleScroll}
            smoothScroll={scrollAnimation}
            type={type}
            loading={!showChatSpinner}
            id={chatBoxRef}
          >
            <ChatMessages
              intl={intl}
              isFetching={isFetching}
              data={messages}
              groupedByDate={groupedByDate}
            />
          </ChatMessagesContainer>

          <ChatIndicator
            channelId={ticket?.ticketChatChannels?.[0]}
            instance={chatInstance}
            active={unreadIndicator}
            messages={messages}
            messagesContainer={chatBoxRef?.current}
          />
          {type !== "closed" && (
            <ChatActions
              intl={intl}
              sendFile={sendFile}
              sendMessage={sendMessage}
            />
          )}
        </div>
      </div>
      {/* <VideoCall intl={intl} user={user} ticketId={ticket?._id} /> */}
    </>
  );
};

export default Chat;
