import Webvis from "./_webvis";

const Machine3DTab = ({ url, onRequestPart, machine }) => {
  return (
    <>
      <Webvis url={url} onRequestPart={onRequestPart} machine={machine} />
    </>
  );
};

export default Machine3DTab;
