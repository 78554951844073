import {
  GET_OWN_CUSTOMER_PRODUCTION_LINE_BY_ID_QUERY,
  LIST_OWN_CUSTOMER_PRODUCTION_LINES_QUERY,
} from "#/src/api";
import { ITEMS_PER_PAGE } from "#/src/constants/global";
import { useQuery } from "@apollo/client";

export const getProuctionLines = ({
  where,
  limit = ITEMS_PER_PAGE,
  skip = 0,
}) => {
  const variables = {
    params: {
      ...(where ? { where } : {}),
      limit,
      skip,
    },
  };

  const {
    data: { listOwnCustomerProductionLines = {} } = {},
    loading,
    fetchMore,
    error,
  } = useQuery(LIST_OWN_CUSTOMER_PRODUCTION_LINES_QUERY, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const handleFetchMore = ({ limit, skip }) => {
    fetchMore({
      variables: {
        ...variables,
        params: {
          ...variables.params,
          limit,
          skip,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const mergedProductionLines = [
          ...previousResult.listOwnCustomerProductionLines.productionLines,
          ...fetchMoreResult.listOwnCustomerProductionLines.productionLines,
        ];

        const updates = {
          ...previousResult,
          listOwnCustomerProductionLines: {
            ...previousResult.listOwnCustomerProductionLines,
            ...fetchMoreResult.listOwnCustomerProductionLines,
            productionLines: mergedProductionLines,
          },
        };

        return updates;
      },
    });
  };

  const {
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
    productionLines = [],
  } = listOwnCustomerProductionLines;

  return {
    productionLines,
    loading,
    error,
    handleFetchMore,
    currentPage,
    limit: itemLimit,
    skip: itemSkip,
    totalCount,
  };
};

export const getProductionLineById = (id) => {
  const {
    data: { getOwnCustomerProductionLineById: productionLine = {} } = {},
  } = useQuery(GET_OWN_CUSTOMER_PRODUCTION_LINE_BY_ID_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  });

  return [productionLine];
};
