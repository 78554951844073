import { Repeat } from "phosphor-react";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import usePopper from "react-overlays/usePopper";

import ProgressBar from "~/components/form/_progressBar";
import EventPreview from "~/components/machine/preventiveMaintenance/EventPreview";
import {
  CalendarBlankIcon,
  HourglassIconSmall,
  InfoIcon,
  ProgressLoadingIcon,
} from "~/icons";
import { formatDate } from "~/utils";

const SingleEvent = ({
  title,
  startDate,
  repeatIn,
  repeatInNumber,
  eventDate,
  description,
  frequency,
  ticketCreationNumber,
  ticketCreationIn,
  nextCreationDate,
}) => {
  const containerRef = useRef();
  const popupRef = useRef();

  const [showPreview, setShowPreview] = useState(false);

  const { styles, attributes } = usePopper(
    containerRef.current,
    popupRef.current,
    {
      placement: "bottom-end",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 0],
          },
        },
      ],
    },
  );

  const {
    messages: { preventiveMaintenance: PM },
  } = useIntl();

  const calculateProgress = useCallback(() => {
    const totalTime = Math.ceil(
      Math.abs(new Date(eventDate).getTime() - new Date(startDate).getTime()),
    );

    let timeElappsed = Math.ceil(
      Math.abs(new Date().getTime() - new Date(eventDate).getTime()),
    );
    if (timeElappsed > totalTime) timeElappsed = 0;
    const result = Math.abs(1 - timeElappsed / totalTime) * 100;

    return parseFloat(result.toFixed(2));
  }, [eventDate]);

  const totalProgress = useMemo(() => calculateProgress(), [calculateProgress]);

  const handleEventPreview = () => {
    setShowPreview(true);
  };

  const handlePreviewOutsideClick = () => {
    setShowPreview(false);
  };

  return (
    <>
      <div className="preventive-event-card">
        <div className="preventive-event-card-body">
          <div className="preventive-event-card-title-icon">
            <span className="pe-icon">
              <HourglassIconSmall />
            </span>

            <h3 className="title">{title}</h3>
          </div>
          <div className="meta-data">
            <div className="pe-row repeat">
              <span className="u-flex u-items-center u-justify-center">
                <span className="pe-icon">
                  <Repeat size={16} />
                </span>
                {PM?.repeatEvery}&nbsp; • &nbsp;
              </span>
              <span className="frequency-time">
                {repeatInNumber !== 0 ? (
                  <>
                    {repeatInNumber} {repeatIn}
                  </>
                ) : (
                  <>{PM?.form.frequencyNoRepeat}</>
                )}
              </span>
            </div>

            <div className="pe-row date-time-progress">
              <span className="pe-icon">
                <CalendarBlankIcon />
              </span>
              {PM?.maintenanceOn}&nbsp; • &nbsp;
              <span className="date">{formatDate(eventDate)}</span>
            </div>
            <div className="pe-row">
              <span className="pe-icon">
                <ProgressLoadingIcon />
              </span>
              {PM?.eventProgressText} &nbsp; • &nbsp;
              <span className="progress-text">{totalProgress}%</span>
              <span className="bar-wrapper">
                <ProgressBar completed={totalProgress} />
              </span>
            </div>
          </div>
        </div>

        <div
          onClick={handleEventPreview}
          ref={containerRef}
          className="u-cursor-pointer preventive-event-action-btn"
        >
          <InfoIcon />
        </div>
      </div>

      <div
        ref={popupRef}
        className="event-tooltip"
        style={{
          visibility: showPreview ? "visible" : "hidden",
          pointerEvents: showPreview ? "initial" : "none",
          zIndex: 2,
          ...styles.popper,
        }}
        {...attributes.popper}
      >
        <EventPreview
          onOutsideClick={handlePreviewOutsideClick}
          title={title}
          repeatIn={repeatIn}
          repeatInNumber={repeatInNumber}
          eventDate={eventDate}
          description={description}
          frequency={frequency}
          nextCreationDate={nextCreationDate}
          ticketCreationIn={ticketCreationIn}
          ticketCreationNumber={ticketCreationNumber}
        />
      </div>
    </>
  );
};

export default SingleEvent;
