import { WarningCircle } from "phosphor-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { COLOR } from "#/src/constants/colors";

const LoginForm = ({
  modifier,
  callback = () => {},
  intl,
  color,
  isLighterBackground,
  buttonStyle,
}) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { email, password } = watch();

  const onSubmit = (data) => {
    callback(data, setLoading);
  };

  const onError = () => {
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
      <div className="form-group auth-formGroup">
        <label
          className="c-label auth-formGroupLabel"
          htmlFor="email"
          // style={{ color: color }}
        >
          {intl.messages?.login?.form.email.label}
        </label>
        <input
          className={`c-input auth-input ${
            errors?.email ? "c-input--error" : ""
          } ${email ? "input-filled" : ""}`}
          id="email"
          name="email"
          type="text"
          placeholder={intl.messages?.login?.form.email.placeholder}
          {...register("email", { required: true })}
        />

        {/* <p className="form-group-validationFeedback u-margin-y-0">
          {errors?.email && intl.messages?.login?.form.email.error}
        </p> */}

        {errors?.email && (
          <p className="input-v2-error-label">
            <WarningCircle className="input-v2-error-icon" size={16} />
            {errors?.email && intl.messages?.login?.form.email.error}
          </p>
        )}
      </div>
      <div className="form-group auth-formGroup input-password">
        <label
          className="c-label auth-formGroupLabel"
          htmlFor="password"
          // style={{ color: color }}
        >
          {intl.messages?.login?.form.password.label}
        </label>
        <input
          className={`c-input auth-input ${
            errors?.password ? "c-input--error" : ""
          } ${password ? "input-filled" : ""}`}
          id="password"
          name="password"
          type="password"
          placeholder={intl.messages?.login?.form.password.placeholder}
          {...register("password", { required: true })}
        />
        {/* <p className="form-group-validationFeedback u-margin-y-0">
          {errors?.password && intl.messages?.login?.form.password.error}
        </p> */}

        {errors?.password && (
          <p className="input-v2-error-label">
            <WarningCircle className="input-v2-error-icon" size={16} />
            {errors?.password && intl.messages?.login?.form.password.error}
          </p>
        )}
      </div>
      <div className="form-group auth-formGroup auth-forgot-password">
        <Link
          to={`/${intl?.locale}/forgot-password${
            modifier ? "/" + modifier : ""
          }`}
          style={{ color: !isLighterBackground ? color : COLOR.$gray_v2_100 }}
        >
          {intl.messages?.common?.forgotPassword}
        </Link>
      </div>
      <div className="form-group auth-submit">
        <button
          className={`c-button c-button--primary ${
            loading && "c-button--disabled"
          }`}
          type="submit"
          style={{ background: color, ...buttonStyle }}
          onClick={() => {
            setLoading(true);
            handleSubmit(onSubmit, onError);
          }}
        >
          {loading
            ? intl.messages?.login?.form.submit.loading
            : intl.messages?.login?.form.submit.default}
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
