import React from "react";
import Avatar from "~/components/_avatar";
import { QrCode } from "phosphor-react";

const markup = (content) => {
  return { __html: content };
};

const ChatMessageItemText = ({ intl, message }) => {
  const { name, isOemUser, isFacilityUser } = message.message?.sender || {};
  const { createdBy, isQRCodeUser, isSystem } = message?.meta || {};

  return (
    <div
      key={message?.timetoken}
      className={`c-chat-boxMedia ${
        isFacilityUser && "c-chat-boxMedia--sender"
      }`}
    >
      <>
        {isOemUser && !isSystem && (
          <Avatar className="c-chat-avatar" name={name} variant="small" />
        )}

        {createdBy && !isQRCodeUser && !isSystem && (
          <Avatar className="c-chat-avatar" name={createdBy} variant="small" />
        )}

        {!isOemUser && isQRCodeUser && !isSystem && (
          <>
            <span
              style={{
                width: "32px",
                height: "32px",
                minWidth: "32px",
                minHeight: "32px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                userSelect: "none",
                color: "#0517f8",
                background: "#e6e8fe",
              }}
            >
              <QrCode size={20} />
            </span>
          </>
        )}

        <div className="c-chat-boxMediaContent">
          <div
            className={`c-chat-boxMediaMessageWrapper ${
              isFacilityUser && "c-chat-boxMedia--sender"
            } text-sm`}
            dangerouslySetInnerHTML={markup(message?.message?.text)}
          ></div>

          <span className="c-chat-boxMediaTime">
            {createdBy || name || ""}
            {", "}
            {new Date(message?.timetoken / 10000)?.toLocaleTimeString(
              intl?.locale,
              {
                hour: "2-digit",
                minute: "2-digit",
              },
            )}
          </span>
        </div>
      </>
    </div>
  );
};

export default ChatMessageItemText;
