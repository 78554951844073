import React from "react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { NotFoundComponent } from "../_notFoundMessage";
import { NoUserAssignedIcon } from "#/src/icons";
import { Drawer } from "../_drawer";
import PartsPreview from "./_partsPreview";
import { PartRow } from "./_part-raw";

const PartsTableWithPreview = ({
  intl,
  filteredParts = [],
  notFoundMessage,
  loading,
  hasInventoryParts,
}) => {
  const [showPreview, setShowPreview] = React.useState(false);
  const [selectedPart, setSelectedPart] = React.useState(null);

  const handleNameClick = (partId) => {
    const selectedPart = filteredParts?.find(
      (part) => part.part._id === partId,
    );
    setShowPreview(true);
    setSelectedPart(selectedPart.part);
  };

  const handlePreviewClose = () => {
    setShowPreview(false);
    setSelectedPart(null);
  };

  if (loading) return null;

  return (
    <>
      {filteredParts?.length > 0 ? (
        <>
          <div className="parts-table__head u-flex sm-hide">
            <div className="parts-table__col u-width-4">
              <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
                {intl?.messages?.parts?.labels.name}
              </BodyText>
            </div>
            <div className="parts-table__col u-width-4">
              <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
                {intl?.messages?.parts?.labels.description}
              </BodyText>
            </div>
            <div className="parts-table__col u-width-2"></div>
            <div className="parts-table__col u-width-2">
              <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
                {intl?.messages?.parts?.labels.articleNumber}
              </BodyText>
            </div>
          </div>
          {filteredParts?.map((part, index) => {
            return (
              <PartRow
                key={part.part._id}
                part={part.part}
                handlers={{ handleNameClick }}
              />
            );
          })}
        </>
      ) : (
        <NotFoundComponent
          icon={<NoUserAssignedIcon />}
          title={
            hasInventoryParts
              ? intl.messages?.common?.noResultFound
              : notFoundMessage ?? intl.messages?.parts?.noPartsFound
          }
        />
      )}

      <Drawer
        hideFooter
        isOpen={showPreview}
        onClose={handlePreviewClose}
        title={selectedPart?.name}
      >
        <PartsPreview data={selectedPart} />
      </Drawer>
    </>
  );
};

export default PartsTableWithPreview;
