import React from "react";

const MessgaeSpinner = ({ text, className }) => {
  return (
    <div
      className={`c-spinner c-spinner--loading ${
        className || "c-chat-boxMediaSpinner"
      }`}
    >
      <span></span>
      <span></span>
      <span></span>
      {text ? <p className="c-chat-boxMediaSpinnerText">{text}</p> : null}
    </div>
  );
};

export default MessgaeSpinner;
