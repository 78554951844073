import React from "react";

const EmptySpace = ({ height, className, ...props }) => {
  return (
    <div
      className={`empty-space ${className || ""}`}
      style={{ height: height || "16px" }}
      {...props}
    ></div>
  );
};

export default EmptySpace;
