import * as React from "react";
import MachinesQrcSingle from "~/containers/_machinesQrcSingle.jsx";
import MachinesSingle from "~/containers/_machinesSingle.jsx";
import Assets from "~/containers/_assets";
import SingleProuctionLine from "~/containers/_productionLineSingle";
import ComponentPage from "#/src/containers/_componentPage";
import { SidebarAssetsFilledIcon, SidebarAssetsIcon } from "../icons";

export const machinesRoutes = (WithHeader) => {
  return [
    {
      id: "app",
      path: "/app/assets",
      exact: true,
      label: "machines",
      icon: <SidebarAssetsIcon className="icon" size={20} />,
      iconActive: (
        <SidebarAssetsFilledIcon className="icon-active-assets" size={20} />
      ),
      showInNavigation: true,
      main: (props) => {
        var header = () => {
          return {
            title: props.intl.messages.header?.machines?.title,
            navigation: props.intl.messages?.navigation?.["machines"],
            backIcon: false,
            counter: true,
            action: true,
            page: "machines",
          };
        };

        return (
          <WithHeader {...props} header={header()}>
            <Assets {...props} header={header()} />
          </WithHeader>
        );
      },
    },
    {
      id: "app",
      path: "/app/assets/machines",
      exact: true,
      label: "machines",
      showInNavigation: false,
      main: (props) => {
        var header = () => {
          return {
            title: props.intl.messages.header?.machines?.title,
            navigation: props.intl.messages?.navigation?.["machines"],
            backIcon: false,
            counter: true,
            action: true,
            page: "machines",
          };
        };

        return (
          <WithHeader {...props} header={header()}>
            <Assets {...props} header={header()} />
          </WithHeader>
        );
      },
    },
    {
      id: "app",
      path: "/app/assets/production-lines",
      exact: true,
      label: "machines",
      showInNavigation: false,
      main: (props) => {
        var header = () => {
          return {
            title: props.intl.messages.header?.machines?.title,
            navigation: props.intl.messages?.navigation?.["machines"],
            backIcon: false,
            counter: true,
            action: true,
            page: "machines",
          };
        };

        return (
          <WithHeader {...props} header={header()}>
            <Assets {...props} header={header()} />
          </WithHeader>
        );
      },
    },
    {
      id: "app",
      path: "/app/machines/view/:machineUuid",
      exact: true,
      label: "Machines",
      icon: "fas fa-cogs",
      showInNavigation: false,
      main: (props) => {
        return <MachinesQrcSingle {...props} />;
      },
    },
    {
      id: "app",
      path: "/app/assets/machines/:id",
      exact: true,
      label: "Machines",
      icon: "fas fa-cogs",
      showInNavigation: false,
      main: (props) => {
        var header = () => {
          return {
            title: "",
            backIcon: true,
            backUrl: "/en/app/assets/machines",
          };
        };

        return (
          <WithHeader {...props} header={header()}>
            <MachinesSingle {...props} />
          </WithHeader>
        );
      },
    },
    {
      id: "app",
      path: "/app/assets/machines/:machineId/component/:id",
      exact: true,
      label: "Component",
      icon: "fas fa-cogs",
      showInNavigation: false,
      main: (props) => {
        var header = () => {
          return {
            title: "",
            backIcon: true,
            // backUrl: "/en/app/assets/machines",
          };
        };

        return (
          <WithHeader {...props} header={header()}>
            {/* <SinglePageV2 {...props} header={header()}> */}
            <ComponentPage {...props} header={header()} />
            {/* </SinglePageV2> */}
          </WithHeader>
        );
      },
    },
    {
      id: "app",
      path: "/app/assets/production-lines/:id",
      exact: true,
      label: "Production Lines",
      icon: "fas fa-cogs",
      showInNavigation: false,
      main: (props) => {
        var header = () => {
          return {
            title: "",
            backIcon: true,
            backUrl: "/en/app/production-lines",
          };
        };

        return (
          <WithHeader {...props} header={header()}>
            {/* <SinglePageV2 {...props} header={header()}> */}
            <SingleProuctionLine {...props} header={header()} />
            {/* </SinglePageV2> */}
          </WithHeader>
        );
      },
    },
  ];
};
