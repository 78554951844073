import client from "../../apollo/_client";
import { GET_OWN_CUSTOMER_COMPONENT_BY_ID_QUERY } from "~/api";

export const updateComponentCache = (payload) => {
  const component = client.readQuery({
    query: GET_OWN_CUSTOMER_COMPONENT_BY_ID_QUERY,
    variables: { id: payload._id },
  });

  if (!component) return;

  const { getOwnCustomerComponentById: cachedComponent } = component;
  if (cachedComponent) {
    const cloned = { ...cachedComponent };
    cloned.documentFolders = {
      ...(cloned.documentFolders ? cloned.documentFolders : {}),
      ...payload.documentFolders,
    };

    client.writeQuery({
      query: GET_OWN_CUSTOMER_COMPONENT_BY_ID_QUERY,
      variables: { id: payload._id },
      data: {
        getOwnCustomerComponentById: cloned,
      },
    });
  }
};
