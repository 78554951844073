import { User } from "phosphor-react";
import React from "react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { useIntl } from "react-intl";
import { generateInitials } from "~/utils";
import ReactTooltip from "react-tooltip";

// Variants of avatar
// 1. regular
// 2. small
// 3. extra-small

const Avatar = ({
  className,
  variant = "regular",
  name = null,
  showTooltip,
}) => {
  // const userOptions = useRecoilValue(currentUserAtom);
  const classes = [
    `avatar ${className}`,
    variant ? `avatar--${variant}` : "",
  ].join(" ");

  return (
    <>
      <span
        className={classes}
        data-for="avatar-tooltip"
        data-tip={showTooltip ? name ?? "" : ""}
      >
        {generateInitials(name)}
      </span>
      <ReactTooltip
        id="avatar-tooltip"
        effect="solid"
        place="top"
        className="react-tooltip-override"
      />
    </>
  );
};

export const UserAvatar = ({
  className,
  imageSrc,
  name,
  variant = "",
  showName = true,
  showTooltip,
  showUserIcon = false,
  isDeleted = false,
  nameClassName = "",
  ...props
}) => {
  const { messages } = useIntl();
  const classes = ["user-avatar-container", className || ""].join(" ");
  return (
    <div className={classes} {...props}>
      <span className="user-avatar">
        {imageSrc ? (
          <img src={imageSrc} alt={name} />
        ) : name && !showUserIcon ? (
          <Avatar name={name} variant={variant} showTooltip={showTooltip} />
        ) : (
          <User size={12} />
        )}
      </span>
      {showName && (
        <BodyText
          size={BODY_TEXT_SIZES.X_SMALL}
          color={name ? "text-primary" : "text-secondary"}
          className={`ml-xs ${nameClassName}`}
        >
          {name || messages?.common?.notAssignedYet}
          {isDeleted ? ` - ${messages.common.deleted}` : ""}
        </BodyText>
      )}
    </div>
  );
};

export const DropdownAvatar = ({
  name,
  className,
  variant = "extra-small",
}) => {
  const classes = [
    `avatar ${className}`,
    variant ? `avatar--${variant}` : "",
  ].join(" ");

  return <span className={classes}>{generateInitials(name)}</span>;
};

export default Avatar;
