import React from "react";

const Spinner = () => {
  return (
    <div className="c-spinner c-spinner--loading">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default Spinner;
