import * as React from "react";

import { machinesRoutes } from "~/routes/_machines.jsx";
import { settingRoutes } from "~/routes/_settings.jsx";
import { ticketRoutes } from "~/routes/_tickets.jsx";

const WithHeader = React.lazy(() => import("~/layout/_withHeader.jsx"));

const routes = [
  ...ticketRoutes(WithHeader),
  ...machinesRoutes(WithHeader),
  ...settingRoutes(WithHeader),
];

export default (user) =>
  routes.filter(
    (route) => (!route.enableIf || route.enableIf(user)) && route.id === "app",
  );
