import React from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";

const ProductionLineListItem = ({
  intl,
  productionLine,
  variant = "small",
  inline,
}) => {
  const { messages } = useIntl();
  return (
    <Link
      to={`/${intl?.locale}/app/assets/production-lines/${productionLine?._id}`}
    >
      <div className="production-line-list-item-wrapper">
        <div className="production-line-name">
          <div className="text u-inline-flex u-items-center">
            <Headline
              size={HEADLINE_SIZES.X_SMALL}
              className="overflow-hidden text-ellipsis whitespace-nowrap capitalize"
              title={productionLine?.name}
            >
              {productionLine?.name}
            </Headline>
          </div>
        </div>

        <div className="machines-count">
          <div className={"text machine-wrapper u-truncate"}>
            <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
              {productionLine?.numberOfMachines}
              <> &nbsp;</>
            </BodyText>
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              className="truncate"
              title={messages?.productionLines.labels.machines}
            >
              {messages?.productionLines.labels.machines}
            </BodyText>
          </div>
        </div>
        <div className="production-line-id">
          <div className={"text u-flex inline u-items-center u-truncate"}>
            <BodyText size={BODY_TEXT_SIZES.X_SMALL} color="text-secondary">
              {messages?.productionLines.labels.id}
              <>: &nbsp;</>
            </BodyText>
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              className="truncate"
              title={productionLine?.lineId}
            >
              {productionLine?.lineId}
            </BodyText>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProductionLineListItem;
